import { loadScript } from "vue-plugin-load-script";

function loadAnalyticsScripts(store) {
  if (store.getters["Cookies/loadedAnalyticsScripts"]) {
    return; // Already loaded
  }

  if (typeof process.env.VUE_APP_GOOGLE_MEASUREMENT_ID !== 'undefined'
    && process.env.VUE_APP_GOOGLE_MEASUREMENT_ID !== null
    && process.env.VUE_APP_GOOGLE_MEASUREMENT_ID.length > 0
    // && store.getters["Cookies/acceptedAnalyticsCookies"]
  ) {
    loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_MEASUREMENT_ID}`)
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line no-undef
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', process.env.VUE_APP_GOOGLE_MEASUREMENT_ID);
      });

    store.dispatch('Cookies/setLoadedAnalyticsScripts');
  }
}

export default {
  install: (app, options) => {
    const store = options.store;

    store.subscribe((mutation) => {
      if (mutation.type !== 'Cookies/setAcceptedCookies') {
        return;
      }

      loadAnalyticsScripts(store);
    });

    loadAnalyticsScripts(store);
  },
}
