import http from "@/libs/axios";
import api from "@/api";

export default {
    namespaced: true,

    actions: {
        async getCoupon(_, code) {
            const response = await http.post(api.coupons.show, {code});

            return response.data.coupon;
        },
    }
}
