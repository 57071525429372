export default [
    {code: "AF", phoneCode: "+93", tax: 0},
    {code: "AX", phoneCode: "+358", tax: 0},
    {code: "AL", phoneCode: "+355", tax: 0},
    {code: "DZ", phoneCode: "+213", tax: 0},
    {code: "AS", phoneCode: "+1684", tax: 0},
    {code: "AO", phoneCode: "+244", tax: 0},
    {code: "AI", phoneCode: "+1264", tax: 0},
    {code: "AQ", phoneCode: "+672", tax: 0},
    {code: "AG", phoneCode: "+1268", tax: 0},
    {code: "AR", phoneCode: "+54", tax: 0},
    {code: "AM", phoneCode: "+374", tax: 0},
    {code: "AW", phoneCode: "+297", tax: 0},
    {code: "AU", phoneCode: "+61", tax: 0},
    {code: "AT", phoneCode: "+43", tax: 21},
    {code: "AZ", phoneCode: "+994", tax: 0},
    {code: "BS", phoneCode: "+1242", tax: 0},
    {code: "BH", phoneCode: "+973", tax: 0},
    {code: "BD", phoneCode: "+880", tax: 0},
    {code: "BB", phoneCode: "+1246", tax: 0},
    {code: "BY", phoneCode: "+375", tax: 0},
    {code: "BE", phoneCode: "+32", tax: 0},
    {code: "BZ", phoneCode: "+501", tax: 0},
    {code: "BJ", phoneCode: "+229", tax: 0},
    {code: "BM", phoneCode: "+1441", tax: 0},
    {code: "BT", phoneCode: "+975", tax: 0},
    {code: "BO", phoneCode: "+591", tax: 0},
    {code: "BA", phoneCode: "+387", tax: 0},
    {code: "BW", phoneCode: "+267", tax: 0},
    {code: "BR", phoneCode: "+55", tax: 0},
    {code: "IO", phoneCode: "+246", tax: 0},
    {code: "BN", phoneCode: "+673", tax: 0},
    {code: "BG", phoneCode: "+359", tax: 21},
    {code: "BF", phoneCode: "+226", tax: 0},
    {code: "BI", phoneCode: "+257", tax: 0},
    {code: "KH", phoneCode: "+855", tax: 0},
    {code: "CM", phoneCode: "+237", tax: 0},
    {code: "CA", phoneCode: "+1", tax: 0},
    {code: "CV", phoneCode: "+238", tax: 0},
    {code: "KY", phoneCode: "+345", tax: 0},
    {code: "CF", phoneCode: "+236", tax: 0},
    {code: "TD", phoneCode: "+235", tax: 0},
    {code: "CL", phoneCode: "+56", tax: 0},
    {code: "CN", phoneCode: "+86", tax: 0},
    {code: "CX", phoneCode: "+61", tax: 0},
    {code: "CC", phoneCode: "+61", tax: 0},
    {code: "CO", phoneCode: "+57", tax: 0},
    {code: "KM", phoneCode: "+269", tax: 0},
    {code: "CG", phoneCode: "+242", tax: 0},
    {code: "CD", phoneCode: "+243", tax: 0},
    {code: "CK", phoneCode: "+682", tax: 0},
    {code: "CR", phoneCode: "+506", tax: 0},
    {code: "CI", phoneCode: "+225", tax: 0},
    {code: "HR", phoneCode: "+385", tax: 21},
    {code: "CU", phoneCode: "+53", tax: 0},
    {code: "CY", phoneCode: "+357", tax: 21},
    {code: "CZ", phoneCode: "+420", tax: 21},
    {code: "DK", phoneCode: "+45", tax: 21},
    {code: "DJ", phoneCode: "+253", tax: 0},
    {code: "DM", phoneCode: "+1767", tax: 0},
    {code: "DO", phoneCode: "+1849", tax: 0},
    {code: "EC", phoneCode: "+593", tax: 0},
    {code: "EG", phoneCode: "+20", tax: 0},
    {code: "SV", phoneCode: "+503", tax: 0},
    {code: "GQ", phoneCode: "+240", tax: 0},
    {code: "ER", phoneCode: "+291", tax: 0},
    {code: "EE", phoneCode: "+372", tax: 21},
    {code: "ET", phoneCode: "+251", tax: 0},
    {code: "FK", phoneCode: "+500", tax: 0},
    {code: "FO", phoneCode: "+298", tax: 0},
    {code: "FJ", phoneCode: "+679", tax: 0},
    {code: "FI", phoneCode: "+358", tax: 21},
    {code: "FR", phoneCode: "+33", tax: 21},
    {code: "GF", phoneCode: "+594", tax: 0},
    {code: "PF", phoneCode: "+689", tax: 0},
    {code: "GA", phoneCode: "+241", tax: 0},
    {code: "GM", phoneCode: "+220", tax: 0},
    {code: "GE", phoneCode: "+995", tax: 0},
    {code: "DE", phoneCode: "+49", tax: 21},
    {code: "GH", phoneCode: "+233", tax: 0},
    {code: "GI", phoneCode: "+350", tax: 0},
    {code: "GR", phoneCode: "+30", tax: 21},
    {code: "GL", phoneCode: "+299", tax: 0},
    {code: "GD", phoneCode: "+1473", tax: 0},
    {code: "GP", phoneCode: "+590", tax: 0},
    {code: "GU", phoneCode: "+1671", tax: 0},
    {code: "GG", phoneCode: "+44", tax: 0},
    {code: "GN", phoneCode: "+224", tax: 0},
    {code: "GW", phoneCode: "+245", tax: 0},
    {code: "GY", phoneCode: "+595", tax: 0},
    {code: "HT", phoneCode: "+509", tax: 0},
    {code: "VA", phoneCode: "+379", tax: 0},
    {code: "HN", phoneCode: "+504", tax: 0},
    {code: "HK", phoneCode: "+852", tax: 0},
    {code: "HU", phoneCode: "+36", tax: 21},
    {code: "IS", phoneCode: "+354", tax: 0},
    {code: "IN", phoneCode: "+91", tax: 0},
    {code: "ID", phoneCode: "+62", tax: 0},
    {code: "IR", phoneCode: "+98", tax: 0},
    {code: "IQ", phoneCode: "+964", tax: 0},
    {code: "IE", phoneCode: "+353", tax: 21},
    {code: "IM", phoneCode: "+44", tax: 0},
    {code: "IL", phoneCode: "+972", tax: 0},
    {code: "IT", phoneCode: "+39", tax: 21},
    {code: "JM", phoneCode: "+1876", tax: 0},
    {code: "JP", phoneCode: "+81", tax: 0},
    {code: "JE", phoneCode: "+44", tax: 0},
    {code: "JO", phoneCode: "+962", tax: 0},
    {code: "KZ", phoneCode: "+77", tax: 0},
    {code: "KE", phoneCode: "+254", tax: 0},
    {code: "KI", phoneCode: "+686", tax: 0},
    {code: "KP", phoneCode: "+850", tax: 0},
    {code: "KR", phoneCode: "+82", tax: 0},
    {code: "KW", phoneCode: "+965", tax: 0},
    {code: "KG", phoneCode: "+996", tax: 0},
    {code: "LA", phoneCode: "+856", tax: 0},
    {code: "LV", phoneCode: "+371", tax: 21},
    {code: "LB", phoneCode: "+961", tax: 0},
    {code: "LS", phoneCode: "+266", tax: 0},
    {code: "LR", phoneCode: "+231", tax: 0},
    {code: "LY", phoneCode: "+218", tax: 0},
    {code: "LI", phoneCode: "+423", tax: 0},
    {code: "LT", phoneCode: "+370", tax: 21},
    {code: "LU", phoneCode: "+352", tax: 21},
    {code: "MO", phoneCode: "+853", tax: 0},
    {code: "MK", phoneCode: "+389", tax: 0},
    {code: "MG", phoneCode: "+261", tax: 0},
    {code: "MW", phoneCode: "+265", tax: 0},
    {code: "MY", phoneCode: "+60", tax: 0},
    {code: "MV", phoneCode: "+960", tax: 0},
    {code: "ML", phoneCode: "+356", tax: 0},
    {code: "MT", phoneCode: "+356", tax: 21},
    {code: "MH", phoneCode: "+692", tax: 0},
    {code: "MTQ", phoneCode: "+596", tax: 0},
    {code: "MQ", phoneCode: "+222", tax: 0},
    {code: "MU", phoneCode: "+230", tax: 0},
    {code: "YT", phoneCode: "+262", tax: 0},
    {code: "MX", phoneCode: "+52", tax: 0},
    {code: "FM", phoneCode: "+691", tax: 0},
    {code: "MD", phoneCode: "+373", tax: 0},
    {code: "MC", phoneCode: "+377", tax: 0},
    {code: "MN", phoneCode: "+976", tax: 0},
    {code: "ME", phoneCode: "+382", tax: 0},
    {code: "MS", phoneCode: "+1664", tax: 0},
    {code: "MA", phoneCode: "+212", tax: 0},
    {code: "MZ", phoneCode: "+258", tax: 0},
    {code: "MM", phoneCode: "+95", tax: 0},
    {code: "NA", phoneCode: "+264", tax: 0},
    {code: "NR", phoneCode: "+674", tax: 0},
    {code: "NP", phoneCode: "+977", tax: 0},
    {code: "NL", phoneCode: "+31", tax: 21},
    {code: "AN", phoneCode: "+599", tax: 0},
    {code: "NC", phoneCode: "+687", tax: 0},
    {code: "NZ", phoneCode: "+64", tax: 0},
    {code: "NI", phoneCode: "+505", tax: 0},
    {code: "NE", phoneCode: "+227", tax: 0},
    {code: "NG", phoneCode: "+234", tax: 0},
    {code: "NU", phoneCode: "+683", tax: 0},
    {code: "NF", phoneCode: "+672", tax: 0},
    {code: "MP", phoneCode: "+1670", tax: 0},
    {code: "NO", phoneCode: "+47", tax: 0},
    {code: "OM", phoneCode: "+968", tax: 0},
    {code: "PK", phoneCode: "+92", tax: 0},
    {code: "PS", phoneCode: "+970", tax: 0},
    {code: "PA", phoneCode: "+507", tax: 0},
    {code: "PG", phoneCode: "+675", tax: 0},
    {code: "PY", phoneCode: "+595", tax: 0},
    {code: "PE", phoneCode: "+51", tax: 0},
    {code: "PH", phoneCode: "+63", tax: 0},
    {code: "PN", phoneCode: "+872", tax: 0},
    {code: "PL", phoneCode: "+48", tax: 21},
    {code: "PT", phoneCode: "+351", tax: 21},
    {code: "PR", phoneCode: "+1939", tax: 0},
    {code: "QA", phoneCode: "+974", tax: 0},
    {code: "RO", phoneCode: "+40", tax: 21},
    {code: "RU", phoneCode: "+7", tax: 0},
    {code: "RW", phoneCode: "+250", tax: 0},
    {code: "RE", phoneCode: "+262", tax: 0},
    {code: "BL", phoneCode: "+590", tax: 0},
    {code: "SH", phoneCode: "+290", tax: 0},
    {code: "KN", phoneCode: "+1869", tax: 0},
    {code: "LC", phoneCode: "+1758", tax: 0},
    {code: "MF", phoneCode: "+590", tax: 0},
    {code: "PM", phoneCode: "+508", tax: 0},
    {code: "VC", phoneCode: "+1784", tax: 0},
    {code: "WS", phoneCode: "+685", tax: 0},
    {code: "SM", phoneCode: "+378", tax: 0},
    {code: "ST", phoneCode: "+239", tax: 0},
    {code: "SA", phoneCode: "+966", tax: 0},
    {code: "SN", phoneCode: "+221", tax: 0},
    {code: "RS", phoneCode: "+381", tax: 0},
    {code: "SC", phoneCode: "+248", tax: 0},
    {code: "SL", phoneCode: "+232", tax: 0},
    {code: "SG", phoneCode: "+65", tax: 0},
    {code: "SK", phoneCode: "+421", tax: 21},
    {code: "SI", phoneCode: "+386", tax: 21},
    {code: "SB", phoneCode: "+677", tax: 0},
    {code: "SO", phoneCode: "+252", tax: 0},
    {code: "ZA", phoneCode: "+27", tax: 0},
    {code: "SS", phoneCode: "+211", tax: 0},
    {code: "GS", phoneCode: "+500", tax: 0},
    {code: "ES", phoneCode: "+34", tax: 21},
    {code: "LK", phoneCode: "+94", tax: 0},
    {code: "SD", phoneCode: "+249", tax: 0},
    {code: "SR", phoneCode: "+597", tax: 0},
    {code: "SJ", phoneCode: "+47", tax: 0},
    {code: "SZ", phoneCode: "+268", tax: 0},
    {code: "SW", phoneCode: "+46", tax: 21},
    {code: "CH", phoneCode: "+41", tax: 0},
    {code: "SY", phoneCode: "+963", tax: 0},
    {code: "TW", phoneCode: "+886", tax: 0},
    {code: "TJ", phoneCode: "+992", tax: 0},
    {code: "TZ", phoneCode: "+255", tax: 0},
    {code: "TH", phoneCode: "+66", tax: 0},
    {code: "TL", phoneCode: "+670", tax: 0},
    {code: "TG", phoneCode: "+228", tax: 0},
    {code: "TK", phoneCode: "+690", tax: 0},
    {code: "TO", phoneCode: "+676", tax: 0},
    {code: "TT", phoneCode: "+1868", tax: 0},
    {code: "TN", phoneCode: "+216", tax: 0},
    {code: "TR", phoneCode: "+90", tax: 0},
    {code: "TM", phoneCode: "+993", tax: 0},
    {code: "TC", phoneCode: "+1649", tax: 0},
    {code: "TV", phoneCode: "+688", tax: 0},
    {code: "UG", phoneCode: "+256", tax: 0},
    {code: "UA", phoneCode: "+380", tax: 0},
    {code: "AE", phoneCode: "+971", tax: 0},
    {code: "GB", phoneCode: "+44", tax: 0},
    {code: "US", phoneCode: "+1", tax: 0},
    {code: "UY", phoneCode: "+598", tax: 0},
    {code: "UZ", phoneCode: "+998", tax: 0},
    {code: "VU", phoneCode: "+678", tax: 0},
    {code: "VE", phoneCode: "+58", tax: 0},
    {code: "VN", phoneCode: "+84", tax: 0},
    {code: "VG", phoneCode: "+1284", tax: 0},
    {code: "VI", phoneCode: "+1340", tax: 0},
    {code: "WF", phoneCode: "+681", tax: 0},
    {code: "YE", phoneCode: "+967", tax: 0},
    {code: "ZM", phoneCode: "+260", tax: 0},
    {code: "ZW", phoneCode: "+263", tax: 0},
];
