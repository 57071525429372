import http from "@/libs/axios";
import api from "@/api";

export default {
    namespaced: true,

    actions: {
        async sendContactForm(_, form) {
            const response = await http.post(api.contactUs, form);

            return response.data.message;
        },
        async sendGetInTouchForm(_, form) {
            const response = await http.post(api.getInTouch, form);

            return response.data.message;
        },
    }
}
