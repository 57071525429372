<template>
  <vue-final-modal v-model="modalOpen"
                   :ssr="false"
                   name="modalNewsletter"
                   classes="flex justify-center items-center overflow-auto my-4 lg:my-0"
                   content-class="relative max-h-full rounded"
                   @click-outside="closeModal()"
                   ref="modal"
  >
    <div class="max-w-[96vw] lg:max-w-[1170px] w-screen box-border modal-product bg-white mx-4 xl:ml-0 xl:mr-[15px]">
      <div class="flex justify-between items-center bg-primary-900 py-2 sm:py-6 px-12">
        <div class="text-green-500 text-xl sm:text-3xl font-bold">
          {{ modalPaywall ? $t('newsletter.titlePaywall') : $t('newsletter.title') }}
        </div>

        <XIcon class="h-8 sm:w-8 inline text-white cursor-pointer" @click="closeModal()"></XIcon>
      </div>

      <Form as="form" class="mt-2 sm:mt-5 p-3 md:px-8 md:py-5" ref="newsletterForm">
        <honey-pot ref="honeypot" />

        <div class="text-primary-900 font-semibold sm:font-medium">
          {{ $t('newsletter.iAmInterestedIn') }}:
        </div>

        <div class="mt-3 sm:mt-5 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div v-for="useCase in useCases" :key="useCase.id"
               class="flex items-start cursor-pointer select-none"
          >
            <div @click="toggleSelectedUseCase(useCase.id)"
                 class="flex-shrink-0 w-5 h-5 border border-primary-800 mr-3 rounded-sm flex items-center justify-center mt-0.5"
            >
              <svg v-if="selectedUseCaseIds.includes(useCase.id)"
                   xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   class="w-4 h-4 text-green-500"
              >
                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill="currentColor" />
              </svg>
            </div>

            <button class="text-primary-900 font-medium text-left"
                    @click.prevent="toggleSelectedUseCase(useCase.id)"
            >
              {{ useCase.title }}
            </button>
          </div>
        </div>

        <div class="mt-4 sm:mt-8 md:mt-14 flex w-full">
          <div class="flex-grow">
            <div class="w-full">
              <label for="newsletter-form-email"
                     class="text-primary-900 font-semibold sm:font-medium"
              >
                {{ $t('calculatorForm.enterYourEmail') }}
              </label>
              <Field class="input-block relative" type="email" rules="required|email|max:255" as="div"
                     name="email"
                     :label="$t('forms.email')"
              >
                <input id="newsletter-form-email" class="standard" type="email"
                       v-model="email"
                />
                <ErrorMessage as="div" name="email" class="errorMessage"/>
              </Field>
            </div>

            <div class="flex items-center cursor-pointer select-none -mt-2 sm:mt-0">
              <div @click="agreeToTerms = !agreeToTerms"
                   class="flex-shrink-0 w-5 h-5 border border-primary-800 mr-3 rounded-sm flex items-center justify-center"
              >
                <svg v-if="agreeToTerms"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     class="w-4 h-4 text-green-500"
                >
                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill="currentColor" />
                </svg>
              </div>

              <div class="font-medium">
                <span @click="agreeToTerms = !agreeToTerms">{{ $t('register.IAgree') }}&nbsp;</span>
                <router-link class="text-blue2 font-medium" to="/terms-conditions" target='_blank'>
                  {{ $t('footer.terms') }}
                </router-link>
              </div>
            </div>
            <div v-if="termsError && !agreeToTerms" v-cloak
                 role="alert" class="errorMessage"
                 style="position: relative; top: 0;">
              {{ $t('newsletter.haveToAgreeToTerms') }}
            </div>
          </div>

          <div class="hidden md:block ml-20 px-20">
            <img src="~@/assets/images/get_newsletter_bell.png"
                 class="w-[89px] h-[97px]"
            />
          </div>
        </div>

        <div class="mt-4 sm:mt-8 flex justify-end">
          <ui-button @click.prevent="submitForm"
                     :arrow="true"
          >
            {{ $t('forms.subscribe') }}
          </ui-button>
        </div>
      </Form>
    </div>
  </vue-final-modal>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'
import {mapActions, mapGetters, mapState} from "vuex";
import {ErrorMessage, Field, Form} from "vee-validate";
import {useToast} from "vue-toastification";
import Cookies from 'js-cookie';

export default {
  name: "newsletterModal",

  setup() {
    const toast = useToast();

    return {toast}
  },

  data() {
    return {
      email: '',
      agreeToTerms: false,
      termsError: false,
      submitting: false,
      selectedUseCaseIds: [],
    }
  },

  computed: {
    ...mapGetters('Auth', {
      isLoggedIn: 'isLoggedIn',
      authUser: 'getAuthUser',
    }),

    ...mapState('Newsletter', ['modalOpen', 'modalPaywall']),

    ...mapGetters('UseCases', ['useCases']),
  },

  watch: {
    modalOpen() {
      this.loadUseCasesIfNeeded();

      this.setModalShownCookie();

      this.setDefaultOptions();
    },
  },

  components: {
    XIcon,
    Form,
    ErrorMessage,
    Field,
  },

  methods: {
    ...mapActions('Newsletter', ['sendNewsletter', 'openModal', 'openModalPaywall', 'closeModal', 'setSubscribed']),
    ...mapActions('UseCases', ['getUseCases']),

    submitForm() {
      if (this.submitting) {
        return;
      }

      this.termsError = false;

      this.$refs.newsletterForm.validate().then(validated => {
        if (!this.agreeToTerms) {
          this.termsError = true;
          return;
        }

        if (validated.valid) {
          this.submitting = true;

          const settings = {
            email: this.email,
            selectedUseCaseIds: this.selectedUseCaseIds,
            agreeToTerms: this.agreeToTerms,
          };

          this.sendNewsletter({email: this.email, useCases: this.selectedUseCaseIds}).then(() => {
            this.toast.success(this.$t('newsletter.subscribed'));

            localStorage.setItem('newsletter-settings', JSON.stringify(settings))

            this.submitting = false;

            this.setSubscribed(true);
          }, (error) => {
            this.$refs.newsletterForm.setErrors(error.response.data.errors)

            this.submitting = false;
          })

          this.closeModal();
        }
      });
    },

    loadUseCasesIfNeeded() {
      if (this.useCases === null || this.useCases.length === 0) {
        if (this.modalOpen === true) {
          this.getUseCases();
        } else {
          setTimeout(() => {
            this.getUseCases();
          }, 1500);
        }
      }
    },

    toggleSelectedUseCase(useCaseId) {
      if (this.selectedUseCaseIds.includes(useCaseId)) {
        this.selectedUseCaseIds = this.selectedUseCaseIds.filter(item => item !== useCaseId);
      } else {
        this.selectedUseCaseIds.push(useCaseId);
      }
    },

    getModalShownCookie() {
      const cookie = Cookies.get('newsletter-modal-shown');

      return (typeof cookie !== 'undefined' && cookie !== null)
          ? JSON.parse(cookie)
          : false;
    },

    setModalShownCookie() {
      Cookies.set('newsletter-modal-shown', JSON.stringify(true), {expires: 365, domain: this.$getCookiesDomain()});
    },

    paywallButtonClickListener(event) {
      if (event.target.classList.contains('paywall-button')) {
        this.openModalPaywall();
      }
    },

    setDefaultOptions() {
      const user = this.$store.getters["Auth/getAuthUser"];

      let email = '';
      let agreeToTerms = false;
      let selectedUseCaseIds = [];

      const localStorageSettings = localStorage.getItem('newsletter-settings')
          ? JSON.parse(localStorage.getItem('newsletter-settings'))
          : null;

      if (localStorageSettings) {
        email = localStorageSettings.email;
        agreeToTerms = localStorageSettings.agreeToTerms;
        selectedUseCaseIds = localStorageSettings.selectedUseCaseIds;
      } else if (user) {
        email = user.email;
      }

      this.$nextTick(() => {
        this.email = email;
        this.agreeToTerms = agreeToTerms;
        this.selectedUseCaseIds = selectedUseCaseIds;

        this.$refs.newsletterForm.resetForm({
          values: {
            email: email,
            agreeToTerms: agreeToTerms,
            selectedUseCaseIds: selectedUseCaseIds,
          },
        });
      })
    },
  },

  mounted() {
    this.loadUseCasesIfNeeded();

    setTimeout(() => {
      if (this.getModalShownCookie() !== true) {
        this.openModal();
      }
    }, 90_000);

    document.body.addEventListener('click', this.paywallButtonClickListener);
  },

  unmounted() {
    document.body.removeEventListener('click', this.paywallButtonClickListener);
  }
}
</script>
