import { createStore } from 'vuex'

// Modules

import Auth from './modules/auth'
import AccountSetting from './modules/accountSetting'
import Regions from './modules/regions'
import Products from './modules/products'
import Coupons from './modules/coupons'
import Checkout from './modules/checkout'
import Orders from './modules/orders'
import Management from './modules/management'
import Support from './modules/support'
import ContactUs from './modules/contactUs'
import Newsletter from './modules/newsletters'
import Tags from './modules/tags'
import Articles from './modules/articles'
import Questions from './modules/questions'
import Cookies from './modules/cookies'
import Savings from './modules/savings'
import Videos from './modules/videos'
import CaseStudies from "@/store/modules/caseStudies";
import UseCases from "@/store/modules/useCases";

const store = createStore({
    modules: {
        Auth,
        AccountSetting,
        Regions,
        Products,
        CaseStudies,
        Coupons,
        Checkout,
        Orders,
        Management,
        Support,
        ContactUs,
        Newsletter,
        Tags,
        Articles,
        Questions,
        Cookies,
        Savings,
        Videos,
        UseCases,
    }
});

export default store;
