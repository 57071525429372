import http from "@/libs/axios";
import api from "@/api";
import Cookies from "js-cookie";

const subscribedCookieKey = 'newsletter-subscribed';

function getSubscribedCookies() {
    const cookiesStr = Cookies.get(subscribedCookieKey);

    return (typeof cookiesStr !== 'undefined' && cookiesStr !== null)
      ? JSON.parse(cookiesStr)
      : false;
}

function setSubscribedCookies(value) {
    let cookieDomain = document.location.hostname;

    if (cookieDomain.startsWith('www.')) {
        cookieDomain = '.' + cookieDomain.substring(4);
    } else if (cookieDomain === 'localhost') {
        // Leave it as 'localhost'
    } else {
        cookieDomain = '.' + cookieDomain;
    }

    Cookies.set(subscribedCookieKey, JSON.stringify(value), {expires: 365, domain: cookieDomain});
}

export default {
    namespaced: true,

    state: {
        modalOpen: false,
        modalPaywall: false,
        subscribed: getSubscribedCookies(),
    },

    mutations: {
        openModal: (state) => {
            state.modalPaywall = false;
            state.modalOpen = true;
        },

        openModalPaywall: (state) => {
            state.modalPaywall = true;
            state.modalOpen = true;
        },

        closeModal: (state) => {
            state.modalOpen = false;
            state.modalPaywall = false;
        },

        setSubscribed: (state, value) => {
            state.subscribed = value;

            setSubscribedCookies(value);
        },
    },

    actions: {
        async sendNewsletter(_, args) {
            let email;
            let useCases = [];

            if (typeof args === 'object' && args !== null) {
                email = args.email;
                useCases = args.useCases ?? [];
            } else {
                email = args;
            }

            await http.post(api.newsletter, {email, use_cases: useCases});
        },

        async openModal({commit}) {
            commit('openModal');
        },

        async openModalPaywall({commit}) {
            commit('openModalPaywall');
        },

        async closeModal({commit}) {
            commit('closeModal');
        },

        async setSubscribed({commit}, value) {
            commit('setSubscribed', value);
        },
    }
}
