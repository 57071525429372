<template>
  <h3>{{ $t('home.GetTouch.h') }}</h3>
  <Form as="form" class="mt-8" @submit="getInTouch" ref="getInTouch">

    <div class="flex mxm-15 flex-wrap">
      <div class="w-full xs:w-1/2 px-15">
        <Field class="input-block relative" rules="max:255" as="div" name="first_name"
               :label="$t('forms.firstName')">
          <p>{{ $t('forms.firstName') }}</p>
          <input class="standard" v-model="user.first_name"/>
          <ErrorMessage as="div" name="first_name" class="errorMessage"/>
        </Field>
      </div>

      <div class="w-full xs:w-1/2 px-15">
        <Field class="input-block relative" rules="max:255" as="div" name="last_name" :label="$t('forms.lastName')">
          <p>{{ $t('forms.lastName') }}</p>
          <input class="standard" v-model="user.last_name"/>
          <ErrorMessage as="div" name="last_name" class="errorMessage"/>
        </Field>
      </div>

      <div class="w-full xs:w-1/2 px-15">
        <Field class="input-block relative" type="email" rules="required|email|max:255" as="div" name="email"
               :label="$t('forms.email')">
          <p>{{ $t('forms.email') }}</p>
          <input class="standard" type="email" v-model="user.email"/>
          <ErrorMessage as="div" name="email" class="errorMessage"/>
        </Field>
      </div>

      <div class="w-full xs:w-1/2 px-15">
        <div class="input-block">
          <p>{{ $t('forms.number') }}</p>
          <Field class="number-block flex items-start relative" type="number" rules="required|max:255" as="div"
                 name="phone" :label="$t('forms.number')">
            <div class="pse-select relative cursor-pointer z-[1]" @mouseleave="showPrefix = false" v-if="selectedCountry">
              <div class="show-code flex items-center" @click="showPrefix = !showPrefix">
                <img :src="getLanguageFlag(selectedCountry.code)" class="w-6 h-4 mr-2"
                     alt="">
                {{ selectedCountry.phoneCode }}
              </div>
              <div class="absolute drop-code border border-box max-h-48 overflow-y-auto" v-show="showPrefix">
                <label v-for="country in countries" :key="country.code" class="inline-flex cursor-pointer"
                       @click="switchPrefix(country.phoneCode)">
                  <input type="radio" class="hidden" value="item.code">
                  <img :src="getLanguageFlag(country.code)" class="w-6 h-4 mr-2" alt="">
                  {{ country.phoneCode }}
                </label>
              </div>
            </div>
            <input type="number" class="standard" v-model="user.phone"/>
            <ErrorMessage as="div" name="phone" class="errorMessage"/>
          </Field>
        </div>
      </div>

      <div class="w-full xs:w-1/2 px-15">
        <Field class="input-block relative" rules="max:255" as="div" name="organization" :label="$t('forms.organization')">
          <p>{{ $t('forms.organization') }}</p>
          <input class="standard" v-model="user.organization"/>
          <ErrorMessage as="div" name="organization" class="errorMessage"/>
        </Field>
      </div>
    </div>

    <div class="flex mxm-15 flex-wrap items-center">
      <div class="w-full xs:w-1/2 px-15">
        <Field class="input-block relative" as="div" rules="max:255" type="select" name="options"
               :label="$t('forms.interestSelect')">
          <p>{{ $t('forms.interest') }}</p>
          <select class="standard cursor-pointer mb-4"
                  v-model="user.option"
                  @input="user.option = $event.target.value"
          >
            <option v-for="option in options" :key="option" :value="option">
              {{ $t(`forms.interestOption.${option}`) }}
            </option>
          </select>
          <ErrorMessage as="div" name="options" class="errorMessage"/>
        </Field>
      </div>
      <div class="w-full xs:w-1/2 px-15">
        <button
            class="blue-btn inline-block btn "
        >{{ $t('forms.submit') }}</button>
      </div>

    </div>
  </Form>
</template>

<script>

import {Form, Field, ErrorMessage} from "vee-validate";
import countries from "@/helpers/countries";

export default {
  name: "GetInTouch",
  components: {
    Form,
    ErrorMessage,
    Field,
  },
  data() {
    return {
      countries,
      showPrefix: false,
      user: {
        phoneCode: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_prefix: "+370",
        organization: "",
        option: "default"
      },
      options: [
          "default", "1", "2", "3"
      ]
    }
  },
  computed: {
    selectedCountry() {
      return this.countries.find((country) => (this.user.phone_prefix ?? '+370') === country.phoneCode)
    }
  },
  methods: {
    switchPrefix(phoneCode) {
      this.user.phone_prefix = phoneCode
      this.showPrefix = false;
    },

    getLanguageFlag(code) {
      try {
        return require(`@/assets/images/languages/${code.toUpperCase()}.svg`)
      } catch (e) {
        return '';
      }
    },
    getInTouch() {
      this.$refs.getInTouch.validate().then(validated => {
        if (validated.valid) {

          console.log(this.user)
          // this.register(this.user).then(() => {
          //   this.toast.success(this.$t('register.registeredSuccessfully'));
          //   this.$router.push({name: 'ProfileView'});
          // }, (error) => {
          //   this.$refs.registrationForm.setErrors(error.response.data.errors)
          // });
        }
      })
    }
  }
}
</script>

<style scoped>
h3{
  color: #1F2F60;
  font-weight: 600;
  font-size: 22px;
  line-height: 60px;
  margin-bottom: 10px;
}
</style>
