<template>
  <div class="w-full mt-6 psevdo-table">
    <div class="thead flex text-center items-center">
      <div class=" w-1/3 sm:w-1/4 cursor-pointer th" @click="updateSort(this.type === 'dtu' ? 'per_hour' : 'size')"
           :class="{'up': this.sortBy.column === (this.type === 'dtu' ? 'per_hour' : 'size') && this.sortBy.ascending === 'DESC'}">
          <span class="inline-flex">
            {{ $t('dataBasePage.' + this.type) }}
            <img src="../../assets/images/arrowDown.svg">
          </span>
      </div>

      <div class=" w-1/3 sm:w-1/4 cursor-pointer th" @click="updateSort('price')"
           :class="{'up': this.sortBy.column === 'price' && this.sortBy.ascending === 'DESC'}">
          <span class="inline-flex">
            {{ $t('dataBasePage.priceMonth') }}
            <img src="../../assets/images/arrowDown.svg">
          </span>
      </div>

      <div class="grow w-12 sm:w-1/2"/>
    </div>

    <div class="tbody">
      <div
          v-for="product in sortedProducts"
          :key="product.id"
          class="tr flex items-center relative"
          :class="{ active: product.is_popular}"
      >
        <div class="hidden absolute spec-offer">
          {{ $t('digitalPage.recommended') }}
        </div>

        <div class="brd-right td w-1/3 sm:w-1/4  lg:px-6">
          {{ type === 'dtu' ? product.attribute.per_hour : `${product.attribute.size}${product.attribute.size_unit}` }}
        </div>

        <div class="td w-1/3 sm:w-1/4">
          {{ product.region.pivot.price > 0 ? $filters.priceWithSpace(product.region.pivot.price) + '€' : $t('ui.free') }}
        </div>

        <div class="td grow w-12 sm:w-1/2">
          <div>
            <div v-if="showOnModal">
              <label class="cursor-pointer flex mb-6 fz14 border-top customRadio">
                <input
                    type="radio"
                    class="hidden"
                    :checked="selectedProductIds.includes(product.id)"
                    :value="product.id"
                    @change="updateSelectedProductId($event.target.value)"
                >
                <span class="checkСircle w-5 h-5 border mr-3 relative"></span>
              </label>
            </div>

            <button v-if="!showOnModal" class="btn hidden blue-btn3 lg:inline-flex mr-3"
                    @click="showModal(product.description)">
              <span>{{ $t('ourProduct.learnMore') }}</span>
            </button>

            <button v-if="!showOnModal" @click="$vfm.show('example')" class="btn blue-btn inline-flex">
             {{ $t('ui.getStarted') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatabaseTablet",

  props: {
    products: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    showOnModal: {
      type: Boolean,
      default: false
    },

    selectedProductIds: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      sortBy: {
        column: 'price',
        ascending: 'ASC'
      }
    }
  },

  computed: {
    sortedProducts() {
      let products = this.products;

      return products.sort((a, b) => {
        return this.sortBy.ascending === 'ASC' ?
            this.sortBy.column === 'price' ? a.region.pivot.price - b.region.pivot.price :
                a.attribute[this.sortBy.column] - b.attribute[this.sortBy.column] :
            this.sortBy.column === 'price' ? b.region.pivot.price - a.region.pivot.price :
                b.attribute[this.sortBy.column] - a.attribute[this.sortBy.column];
      });
    }
  },

  methods: {
    updateSort(column) {
      if (this.sortBy.column !== column) {
        this.sortBy.column = column;
      }

      this.sortBy.ascending = this.sortBy.ascending === 'ASC' ? 'DESC' : 'ASC';
    },

    showModal(description) {
      this.$vfm.show('modalMore', {description})
    },

    updateSelectedProductId(productId) {
      this.$emit('updateSelectedProductId', [parseInt(productId)], this.type)
    },
  }
}
</script>
