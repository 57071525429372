import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        regionId: null, // Selected region id
        regions: [],
    },

    mutations: {
        setRegions: (state, regions) => {
            state.regions = regions;
        },

        setRegionId: (state, regionId) => {
            state.regionId = regionId;
        },
    },

    actions: {
        async getRegions({commit}) {
            const response = await http.get(api.regions.index);

            commit('setRegions', response.data.regions);
            commit('setRegionId', response.data.regions[0].id);

            return response.data.regions;
        },
    },

    getters: {
        getRegionId: (state) => {
            return state.regionId;
        },
    },
}
