<template>
  <vue-final-modal v-model="show"
                   :ssr="false"
                   name="modalCalculatorEmailForm"
                   classes="flex justify-center items-center overflow-auto"
                   content-class="relative max-h-full rounded"
                   @beforeOpen="modalOpen"
                   ref="modal"
  >
    <div class="max-w-xl container box-border modal-product bg-white my-20 p-3 md:px-8 md:py-5">
      <div class="flex justify-between">
        <div class="text-primary-700 text-3xl font-bold">
          {{ $t('calculatorForm.getYourResults') }}
        </div>

        <XIcon class="h-6 w-6 inline text-grey cursor-pointer" @click="show = false"></XIcon>
      </div>

      <Form as="form" class="mt-8" ref="calculatorEmailForm">
        <honey-pot ref="honeypot" />

        <div class="w-full">
          <label for="calculator-form-email">

          </label>
          <Field class="input-block relative" type="email" rules="required|email|max:255" as="div"
                 name="email"
                 :label="$t('forms.email')"
          >
            <input id="calculator-form-email" class="standard" type="email"
                   :placeholder="$t('calculatorForm.enterYourEmail')"
                   v-model="email"
            />
            <ErrorMessage as="div" name="email" class="errorMessage"/>
          </Field>
        </div>

        <div @click="sendOverEmail = !sendOverEmail"
             class="flex items-center cursor-pointer select-none"
        >
          <div class="w-5 h-5 border border-primary-800 mr-3 rounded-sm flex items-center justify-center">
            <svg v-if="sendOverEmail"
                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 class="w-4 h-4 text-green-500"
            >
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill="currentColor" />
            </svg>
          </div>

          <div class="font-medium">
            {{ $t('calculatorForm.sendResultsByEmail') }}
          </div>
        </div>

        <div class="mt-8 flex justify-end">
          <ui-button @click.prevent="submitForm"
                     :arrow="true"
          >
            {{ $t('calculatorForm.goToResults') }}
          </ui-button>
        </div>
      </Form>
    </div>
  </vue-final-modal>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'
import {mapGetters} from "vuex";
import {ErrorMessage, Field, Form} from "vee-validate";

export default {
  name: "CalculatorEmailForm",

  data() {
    return {
      show: false,

      email: '',
      sendOverEmail: true,
    }
  },

  computed: {
    ...mapGetters('Auth', {
      isLoggedIn: 'isLoggedIn',
      authUser: 'getAuthUser',
    }),
  },

  components: {
    XIcon,
    Form,
    ErrorMessage,
    Field,
  },


  methods: {
    modalOpen() {
      //
    },

    submitForm() {
      this.$refs.calculatorEmailForm.validate().then(validated => {
        if (validated.valid) {
          this.$emit('email-set', {
            email: this.email,
            sendOverEmail: this.sendOverEmail,
          });

          this.show = false;
        }
      });
    },
  },
}
</script>
