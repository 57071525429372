<template>
  <vue-final-modal v-model="modalOpen"
                   :ssr="false"
                   name="modalNextStep"
                   classes="flex justify-center items-center overflow-auto my-4 lg:my-0"
                   content-class="relative max-h-full rounded"
                   @click-outside="closeModal()"
                   ref="modal"
  >
    <div class="max-w-[96vw] lg:max-w-[900px] w-screen box-border modal-product bg-white mx-4 xl:ml-0 xl:mr-[15px]">
      <div class="flex justify-between items-center py-2 sm:py-6 px-12">
        <div class="text-primary-900 text-xl sm:text-3xl font-bold uppercase">
          {{ $t('nextStepModal.title') }}
        </div>

        <XIcon class="h-8 sm:w-8 inline text-gray-300 cursor-pointer" @click="closeModal()"></XIcon>
      </div>

      <div class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between items-center mt-4 pb-12 px-12">
        <a :href="links[key]"
           target="_blank"
           v-for="key in ['goToEasas', 'manual' , 'videoTutorial']" :key="key"
           class="bg-primary-800 flex justify-center items-center text-green-500 font-semibold text-lg uppercase w-48 h-48 sm:w-44 sm:h-44 md:w-56 md:h-56 px-4 border-2 border-transparent hover:text-primary-800 hover:bg-very-light-gray hover:border-primary-800"
        >
          <!-- border-2 border-transparent hover:border-primary-800 bg-primary-800 hover:bg-very-light-gray text-white border-2 border-transparent hover:text-primary-800 pt-16 px-8 pb-8 flex flex-col items-center -->
          <div class="flex items-center space-x-4">
            <div class="text-center"
                 v-html="$t(`nextStepModal.${key}`)"
            ></div>

            <img src="@/assets/images/partners/arrows.png"
                 class="w-14"
            />
          </div>
        </a>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'
import {mapActions, mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import http from "@/libs/axios";
import api from "@/api";
import emitter from 'tiny-emitter/instance'

export default {
  name: "NextStepModal",

  setup() {
    const toast = useToast();

    return {toast};
  },

  data() {
    return {
      email: '',
      agreeToTerms: false,
      termsError: false,
      submitting: false,
      selectedUseCaseIds: [],

      links: {
        goToEasas: 'http://login.easas.net/',
        manual: 'https://easas.energyadvice.lt/manuals/easas_manual/',
        videoTutorial: 'https://www.youtube.com/@energyadvice3983/videos',
      },

      modalOpen: false,
    }
  },

  computed: {
    ...mapGetters('Auth', {
      isLoggedIn: 'isLoggedIn',
      authUser: 'getAuthUser',
    }),
  },

  watch: {
    $route() {
      this.showModalIfShould();
    },

    authUser() {
      this.showModalIfShould();
    },
  },

  components: {
    XIcon,
  },

  methods: {
    ...mapActions('AccountSetting', {
      updateUserInfo: 'show'
    }),

    showModalIfShould() {
      if (this.isLoggedIn
          && this.authUser
          && !this.authUser.next_step_popup_shown
          && typeof this.$route.name !== 'undefined'
          && this.$route.name === 'ProfileView'
      ) {
        this.modalOpen = true;
      }
    },

    closeModal() {
      this.modalOpen = false;

      http.post(api.accountSetting.nextStepPopupShown)
          .then(() => {
            this.updateUserInfo();
          });
    },
  },

  mounted() {
    emitter.on('open-next-step-modal', () => {
      this.modalOpen = true;
    });
  },
}
</script>
