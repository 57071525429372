<template>
  <div class="wrapper flex flex-col">
    <TheHeaderNew/>

    <RouterView class="grow"></RouterView>

    <TheFooter/>

    <ModalProduct/>

    <ModalLearnMore/>

    <ModalOneTouch/>

    <NewsletterButton />

    <NewsletterModal />

    <NextStepModal />
  </div>
</template>

<script>
import {RouterView} from "vue-router";
import TheHeaderNew from '@/components/TheHeaderNew.vue'
import TheFooter from '@/components/TheFooter.vue'
import ModalProduct from "@/components/ModalProduct";
import ModalLearnMore from "@/components/ModalLearnMore";
import {mapActions, mapState} from "vuex";
import ModalOneTouch from "@/components/ModalOneTouch";
import NewsletterButton from "@/components/NewsletterButton.vue";
import NewsletterModal from "@/components/NewsletterModal.vue";
import NextStepModal from "@/components/NextStepModal.vue";

export default {
  name: 'App',

  components: {
    NextStepModal,
    NewsletterModal,
    NewsletterButton,
    ModalOneTouch,
    ModalLearnMore,
    ModalProduct,
    RouterView,
    TheHeaderNew,
    TheFooter
  },

  created() {
    this.loadProducts();
    this.loadOneTimeProducts();
    this.getRegions();

    if (this.authUser) {
      this.show();
    }
  },

  computed: {
    ...mapState('Auth', ['authUser']),
  },

  methods: {
    ...mapActions('Products', ['loadProducts', 'loadOneTimeProducts']),
    ...mapActions('Regions', ['getRegions']),
    ...mapActions('AccountSetting', ['show']),
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const defaultTitle = this.$t('routeTitles.default');
        const title = (typeof to.name !== 'undefined' && this.$te(`routeTitles.${to.name}`))
          ? this.$t(`routeTitles.${to.name}`)
          : null;

        if (to.name !== 'ArticleView') {
          document.title = 'EA-SAS Cloud - ' + (title || defaultTitle);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/_product.scss";
</style>
