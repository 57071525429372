<template>
  <div class="flex flex-col xl:flex-row xl:overflow-x-auto gap-2 sm:pt-12">
    <div class="hidden sm:flex xl:inline-block xl:flex-none header-iot text-center xl:text-left w-full xl:w-60 xl:pt-[55px]">
      <div class="h-auto xl:h-[80px] w-1/5 xl:w-full"></div>
      <p class="h-auto xl:h-14 w-1/5 xl:w-full text-xs md:text-base">{{ $t('iotPage.price') }}</p>
      <p class="h-auto xl:h-14 w-1/5 xl:w-full text-xs md:text-base">{{ $t('iotPage.totalNumber') }}</p>
      <p class="h-auto xl:h-14 w-1/5 xl:w-full text-xs md:text-base">{{ $t('iotPage.messageSize') }}</p>
    </div>

    <div v-for="product in products" :key="product.id"
         class="flex flex-col sm:flex-row xl:inline-block xl:flex-1 relative body-iot bg-white w-full items-center border-b-2 sm:border-b-0 py-8 sm:py-0"
         :class="{ active: product.is_popular}"
    >
      <div class="hidden xl:block absolute spec-offer" v-if="product.is_popular">
        {{ $t('digitalPage.recommended') }}
      </div>

      <div class="mb-2 sm:mb-0 text-center h-auto xl:h-20 sm:w-1/5 xl:w-full">
        <div class="font-bold text-3xl sm:text-base xl:text-3xl text-blue">{{ product.attribute.basic }}</div>
        <span class="hidden xl:inline-block">{{ product.region.name }}</span>
      </div>

      <p class="h-auto xl:h-14 sm:w-1/5 xl:w-full font-medium text-xl sm:text-xs md:text-base xl:text-3xl text-blue text-center" style="border: none">
        {{ product.region.pivot.price > 0 ? $filters.priceWithSpace(product.region.pivot.price) + '€' : $t('ui.free') }}
      </p>
      <p class="mb-2 sm:mb-0 -mt-1 text-xs sm:hidden">{{ $t('iotPage.price') }}</p>

      <p class="h-auto xl:h-14 sm:w-1/5 xl:w-full text-xl sm:text-xs md:text-base text-center">{{ product.attribute.total_messages }}</p>
      <p class="mb-2 sm:mb-0 -mt-1 text-xs sm:hidden">{{ $t('iotPage.totalNumber') }}</p>

      <p class="h-auto xl:h-14 sm:w-1/5 xl:w-full text-xl sm:text-xs md:text-base text-center">{{ product.attribute.message_meter_size }}kB</p>
      <p class="mb-2 sm:mb-0 -mt-1 text-xs sm:hidden">{{ $t('iotPage.messageSize') }}</p>

      <div class="w-full sm:w-1/5 xl:w-full" :class="{'sm:grow' : !showOnModal}">
        <div v-if="showOnModal" class="text-center mt-4">
          <label class="cursor-pointer flex mb-6 fz14 border-top customRadio my-auto ">
            <input
                type="radio"
                class="hidden"
                :checked="selectedProductIds.includes(product.id)"
                :value="product.id"
                @change="updateSelectedProductId($event.target.value)"
            >
            <span class="checkСircle w-5 h-5 border mr-3 relative"></span>
          </label>
        </div>
        <div class="text-center" v-else>
          <button class="w-full xl:w-auto btn blue-btn3 my-auto xl:inline-flex mb-2" @click="showModal(product.description)">
            {{ $t('ourProduct.learnMore') }}
          </button>

          <button @click="$vfm.show('example')" class="w-full xl:w-auto btn blue-btn">
            {{ $t('ui.getStarted') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IotTablet",

  props: {
    products: {
      type: Array,
      required: true
    },

    showOnModal: {
      type: Boolean,
      default: false
    },

    selectedProductIds: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    updateSelectedProductId(productId) {
      this.$emit('updateSelectedProductId', [parseInt(productId)], 'iot_hub')
    },

    showModal(description) {
      this.$vfm.show('modalMore', {description})
    },
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 500px) {
  .body-iot {
    .btn {
      height: auto;
      font-size: 10px;
      padding: 10px;
      line-height: 15px;
    }
  }
}
</style>
