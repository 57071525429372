import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        useCases: [],
    },

    mutations: {
        setUseCases: (state, useCases) => {
            state.useCases = useCases;
        },
    },

    getters: {
        useCases: (state) => {
            return state.useCases;
        },
    },

    actions: {
        async getUseCases({commit}) {
            const response = await http.get(api.useCases.index);

            commit('setUseCases', JSON.parse(JSON.stringify(response.data.useCases)));

            return JSON.parse(JSON.stringify(response.data.useCases));
        },
    }
}
