<template>
  <vue-final-modal v-model="show"
                   :ssr="false"
                   name="modalMore"
                   classes="flex justify-center items-center overflow-auto"
                   content-class="relative max-h-full rounded"
                   @closed="closeModal"
                   @beforeOpen="modalOpen"
                   ref="modalMore"
  >
    <div class="container box-border modal-product bg-white my-20 p-3 md:px-8 md:py-5">
      <div class="close text-right">
        <XIcon class="h-6 w-6 inline text-grey cursor-pointer" @click="show = false"></XIcon>
      </div>

      <div style="margin: auto; max-width: 1106px"
           class="wysiwyg-preview prose ck-content"
           v-html="description"
      ></div>
    </div>
  </vue-final-modal>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'

export default {
  name: "ModalLearnMore",

  data: () => ({
    show: false,
    description: ''
  }),

  components: {
    XIcon,
  },

  methods: {
    modalOpen() {
      this.description = this.$refs.modalMore.params.description
    },

    closeModal() {
      this.description = ''
    }
  }
}
</script>
