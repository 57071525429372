import {loadScript} from "vue-plugin-load-script";
import http from "@/libs/axios";
import api from "@/api";
import store from "@/store";

setTimeout(() => {
  loadScript(`//code.tidio.co/${process.env.VUE_APP_TIDIO_API_KEY}.js`);
}, 3000);

function getTidioVisitor() {
  let tidioState = localStorage.getItem(`tidio_state_${process.env.VUE_APP_TIDIO_API_KEY}`);

  if (typeof tidioState === 'undefined' || tidioState === null) {
    return null;
  }

  tidioState = JSON.parse(tidioState);

  return {
    id: tidioState.visitor.id,
    email: tidioState.visitor.email,
  };
}

store.subscribe((mutation) => {
  if (mutation.type !== 'Auth/setAuthUser') {
    return;
  }

  const user = mutation.payload;

  const phone = (user.phone !== null && user.phone.length > 0 && user.phone_prefix !== null && user.phone_prefix.length > 0)
    ? user.phone_prefix + user.phone
    : null;

  const name = (user.first_name + ' ' + user.last_name).trim();

  const tidioVisitorInfo = {
    distinct_id: user.id,
    email: user.email,
    name: name,
    phone: phone,
  };

  document.tidioIdentify = tidioVisitorInfo;

  if (typeof window.tidioChatApi !== 'undefined') {
    window.tidioChatApi.setVisitorData(tidioVisitorInfo);
  }
});

(function() {
  async function onTidioMessage(data) {
    if (data.data.fromBot || data.data.message === 'preChat') {
      return;
    }

    let tidioVisitor = getTidioVisitor();

    // Visitor email may not be set in localStorage just after new message so try 2nd time in 2 secs
    if (typeof tidioVisitor.email === 'undefined'
      || tidioVisitor.email === null
      || tidioVisitor.email.length === 0
    ) {
      tidioVisitor = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(getTidioVisitor());
        }, 2000);
      });
    }

    let from = null;
    switch (data.type) {
      case ('tidioChat-messageFromVisitor'):
        from = 'visitor';
        break;
      case ('tidioChat-messageFromOperator'):
        from = 'operator';
        break;
    }

    let payload = {
      visitor: {
        id: tidioVisitor.id,
        email: tidioVisitor.email,
      },
      message: data.data.message,
      from: from,
    };

    http.post(api.liveChat, payload);
  }

  if (window.tidioChatApi) {
    window.tidioChatApi.on("messageFromOperator", onTidioMessage);
    window.tidioChatApi.on("messageFromVisitor", onTidioMessage);
  } else {
    document.addEventListener("tidioChat-messageFromOperator", onTidioMessage);
    document.addEventListener("tidioChat-messageFromVisitor", onTidioMessage);
  }
})();
