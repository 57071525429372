<template>
  <div class="w-full mt-6 psevdo-table">
    <div class="thead flex text-center items-center">
      <div class="th md:w-72  !hidden md:!inline-block grow"></div>
      <div class="w-28 sm:w-40 ml-auto md:ml-0 cursor-pointer th p-3">
          <span class="inline-flex">
            {{ $t('dashboardPage.NumberTabs') }}
            <img src="../../assets/images/arrowDown.svg">
          </span>
      </div>

      <div class="w-28 sm:w-40 cursor-pointer th p-3">
          <span class="inline-flex">
            {{ $t('dashboardPage.Price') }}
            <img src="../../assets/images/arrowDown.svg">
          </span>
      </div>

      <div v-if="showOnModal !== 'finish'" class="td w-28 sm:w-40"
           :class="{'w-32' : showOnModal, ' md:w-48 lgw-360' : !showOnModal}">
      </div>
    </div>
    <!--    <div class="bg-white flex-grow">-->
    <!--      {{$t('dashboardPage.genericFunctionality')}}-->
    <!--    </div>-->
    <div class="tbody flex  flex-col-reverse md:flex-row">
      <div class="tr grow w-full md:w-72 flex !my-0  grow flex-col justify-center"><p class="">
        {{ $t('dashboardPage.genericFunctionality') }}</p>
      </div>
      <div class="">
        <div
            v-for="product in products"
            :key="product.id"
            class="tr flex items-center relative !my-0"
        >
          <div class="w-28 sm:w-40 ml-auto md:ml-0">{{ product.attribute.number_of_tabs }}</div>
          <div class="w-28 sm:w-40">
            {{ product.region.pivot.price > 0 ? $filters.priceWithSpace(product.region.pivot.price) + '€' : $t('ui.free') }}
          </div>
          <div v-if="showOnModal !== 'finish'" class="td w-28 sm:mr-0"
               :class="{'sm:w-40 w-32' : showOnModal, ' md:w-48 lgw-360' : !showOnModal}">
            <div v-if="showOnModal">
              <label class="cursor-pointer flex mb-6 fz14 border-top customRadio">
                <input
                    type="radio"
                    class="hidden"
                    :checked="selectedProductIds.includes(product.id)"
                    :value="product.id"
                    @change="updateSelectedProductId($event.target.value)"
                >
                <span class="checkСircle w-5 h-5 border mr-3 relative"></span>
              </label>
            </div>

            <div v-else>
              <button class="btn hidden blue-btn3 lg:inline-flex mr-3" @click="showModal(product.description)">
                {{ $t('ourProduct.learnMore') }}
              </button>

              <button v-if="!showOnModal" @click="$vfm.show('example')" class="btn blue-btn inline-flex">
                {{ $t('ui.getStarted') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardTablet",

  props: {
    products: {
      type: Array,
      required: true
    },

    showOnModal: {
      type: Boolean,
      default: false
    },

    selectedProductIds: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    showModal(description) {
      this.$vfm.show('modalMore', {description})
    },

    updateSelectedProductId(productId) {
      this.$emit('updateSelectedProductId', [parseInt(productId)], 'dashboard')
    },
  }
}
</script>
