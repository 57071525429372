import http from "@/libs/axios";
import api from "@/api";

export default {
    namespaced: true,

    actions: {
        async loadOrders(_, filterStatus) {
            const response = await http.get(api.orders.index, {
                params: {
                    status: filterStatus || null,
                }
            });

            return  response.data.orders;
        },

        async storeOrder(_, order) {
            const response = await http.post(api.orders.store, order);

            return response.data.redirectUrl;
        },

        async storeOneTimeOrder(_, order) {
            const response = await http.post(api.oneTimeOrders.store, order);

            return response.data.redirectUrl;
        },

        async storeCart(_, order) {
            const response = await http.post(api.orders.addToCart, order);

            return response.data;
        },

        async cancelOrder(_, order) {
            if (typeof order === 'object') {
                order = order.id;
            }

            const response = await http.post(api.orders.cancel.replace('{orderId}', order));

            return response.data.order;
        }
    }
}
