<template>
  <vue-final-modal v-model="show"
                   :ssr="false"
                   name="example"
                   classes="flex justify-center items-center overflow-auto "
                   content-class=" max-h-full rounded"
                   @before-open="beforeOpenModal"
                   @closed="closeModal"
                   ref="modal">
    <div class="container box-border modal-product bg-white p-3 md:px-8 md:py-5 my-20 min-w-[90vw] sm:min-w-auto">
      <div>
        <div class="close text-right">
          <XIcon class="h-6 w-6 inline text-grey cursor-pointer" @click="show = false"/>
        </div>
        <h3 class="text-center cursor-pointer ">
          <div class="relative inline-block dropDown text-blue">
            <span>{{ titles[step - 1] }}</span>
          </div>
        </h3>
      </div>

      <div class="progress-bar w-full my-auto flex relative justify-between">
          <span v-for="barStep in steps" :key="barStep" :class="{active : barStep.value === step}">
            {{ barStep.value }}
          </span>
      </div>

      <div class="flex flex-col items-center product-page bg-white pt-10">
        <RegionSwitch @changeRegion="changeRegion" :region="selectedRegions[step - 1]"/>

        <div class="table-scroll3 w-full" v-show="step === 1">
          <IotTablet :products="productsByRegion.filter(product =>  product.type === 'iot_hub')"
                     :showOnModal="true" :selected-product-ids="selectedProductsIds['iot_hub']"
                     @updateSelectedProductId="updateSelectedProductsByType"/>
        </div>

        <div class="table-scroll3 w-full" v-show="step === 2">
          <div class="text-center text-blue mb-4 text-3xl font-bold">
            {{ $t('dataBasePage.btn1') }}
          </div>
          <DatabaseTablet type="dtu" :showOnModal="true" :selected-product-ids="selectedProductsIds['dtu']"
                          :products="productsByRegion.filter(product => product.type === 'dtu')"
                          @updateSelectedProductId="updateSelectedProductsByType"/>

          <div class="text-center text-blue mb-4 mt-5 text-3xl font-bold">
            {{ $t('dataBasePage.btn2') }}
          </div>
          <DatabaseTablet :showOnModal="true" type="storage" :selected-product-ids="selectedProductsIds['storage']"
                          :products="productsByRegion.filter(product => product.type === 'storage' && !product.attribute.is_backup)"
                          @updateSelectedProductId="updateSelectedProductsByType"/>

          <div class="text-center text-blue mb-4 mt-5 text-3xl font-bold">
            {{ $t('dataBasePage.btn3') }}
          </div>
          <DatabaseTablet type="backup" :showOnModal="true" :selected-product-ids="selectedProductsIds['backup']"
                          :products="productsByRegion.filter(product => product.type === 'storage' && product.attribute.is_backup)"
                          @updateSelectedProductId="updateSelectedProductsByType"/>
        </div>

        <div class="table-scroll3 w-full" v-show="step === 3">
          <PythonTablet :products="productsByRegion.filter(product =>  product.type === 'mathematical_function')"
                        :showOnModal="true" :selected-product-ids="selectedProductsIds['mathematical_function']"
                        @updateSelectedProductId="updateSelectedProductsByType"/>
        </div>

        <div class="table-scroll3 w-full" v-show="step === 4">
          <div class="text-center text-blue mb-4 text-3xl font-bold">
            {{ $t('home.menu.Api') }}
          </div>
          <ApiTablet :products="productsByRegion.filter(product => product.type === 'other')"
                     :showOnModal="true" :selected-product-ids="selectedProductsIds['other']"
                     @updateSelectedProductId="updateSelectedProductsByType"/>

          <div class="text-center text-blue mb-4 mt-5 text-3xl font-bold">
            {{ $t('home.menu.Dashboard') }}
          </div>
          <DashboardTablet :products="productsByRegion.filter(product => product.type === 'dashboard')"
                           :showOnModal="true" :selected-product-ids="selectedProductsIds['dashboard']"
                           @updateSelectedProductId="updateSelectedProductsByType"/>
        </div>

        <div class="table-scroll3 w-full" v-show="step === 5">
          <div class="text-center text-blue mb-4 text-3xl font-bold">
            {{ $t('digitalPage.switchFunctionality') }}
          </div>
          <DigitalTablet :showOnModal="true" :selected-product-ids="selectedProductsIds['digital_twin']"
                         :products="productsByRegion.filter(product => product.type === 'digital_twin' && product.attribute.is_for_industry)"
                         @updateSelectedProductId="updateSelectedProductsByType"/>

          <div class="text-center text-blue mb-4 mt-5 text-3xl font-bold">
            {{ $t('digitalPage.switchIndustrial') }}
          </div>
          <DigitalTablet :showOnModal="true" :selected-product-ids="selectedProductsIds['digital_twin']"
                         :products="productsByRegion.filter(product => product.type === 'digital_twin' && !product.attribute.is_for_industry)"
                         @updateSelectedProductId="updateSelectedProductsByType"/>
        </div>
      </div>

      <div class="w-full my-8 text-right  flex justify-between">
        <button class="btn border inline-flex border-grey" v-if="step !== 1 && !this.editKey" @click="step--">
          <span>{{ $t('ui.previous') }}</span>
        </button>

        <button v-if="steps.length > step || editKey"
                :disabled="allowNextStep"
                @click="editKey ? updateCheckout() : step++"
                :class="{'opacity-50': allowNextStep}"
                class="btn border inline-flex border-blue2 ml-auto">
          {{ editKey ? $t('ui.update') : $t('ui.next') }}
        </button>

        <button v-else :disabled="allowNextStep" class="btn border inline-flex border-blue2 ml-1"
                @click="addToCheckout">
          {{ $t('ui.toCheck') }}
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'
import IotTablet from "@/components/product/IotTablet";
import RegionSwitch from "@/components/product/RegionSwitch";
import DatabaseTablet from "@/components/product/DatabaseTablet";
import PythonTablet from "@/components/product/PythonTablet";
import ApiTablet from "@/components/product/ApiTablet";
import DashboardTablet from "@/components/product/DashboardTablet";
import DigitalTablet from "@/components/product/DigitalTablet";
import {mapMutations, mapState} from "vuex";
import {filterProductsByRegion} from "@/helpers/utils";

export default {
  name: "ModalProduct",

  components: {DashboardTablet, ApiTablet, IotTablet, DatabaseTablet, PythonTablet, DigitalTablet, RegionSwitch, XIcon},

  data: () => ({
    show: false,
    step: 1,
    steps: [
      {value: 1, keys: ['iot_hub']},
      {value: 2, keys: ['dtu', 'storage', 'backup']},
      {value: 3, keys: ['mathematical_function']},
      {value: 4, keys: ['other', 'dashboard']},
      {value: 5, keys: ['digital_twin']}
    ],
    selectedProductsIds: {
      iot_hub: [],
      dtu: [],
      storage: [],
      backup: [],
      mathematical_function: [],
      other: [],
      dashboard: [],
      digital_twin: []
    },
    selectedRegions: [],
    editKey: null,
  }),

  computed: {
    ...mapState('Products', ['products']),
    ...mapState('Regions', ['regions']),
    ...mapState('Checkout', ['selectedProducts']),

    titles() {
      return [
        this.$t("iotPage.header"),
        this.$t("dataBasePage.header"),
        this.$t("pythonPage.header2"),
        `${this.$t("dashboardPage.header")} ${this.$t("dashboardPage.header2")}`,
        `${this.$t("digitalPage.header")} ${this.$t("digitalPage.switchIndustrial2")}`
      ];
    },

    productsByRegion() {
      if (this.selectedRegions[this.step - 1]) {
        return filterProductsByRegion(this.products, this.selectedRegions[this.step - 1].id);
      }

      return [];
    },

    allowNextStep() {
      let empty = false;

      this.steps[this.step - 1].keys.map((key) => {
        if (!['other', 'digital_twin'].includes(key) && !this.selectedProductsIds[key].length) {
          empty = true;
        }
      })

      return empty;
    }
  },

  methods: {
    ...mapMutations('Checkout', ['setSelectedProducts', "updateSelectedProducts"]),

    beforeOpenModal() {
      this.editKey = this.$refs.modal.params.key ?? null;
      let products = this.$refs.modal.params.products ?? null;

      if (this.editKey || products) {
        products = products ? products : this.selectedProducts;

        this.steps.map((step) => {
          step.keys.includes(this.editKey) ? this.step = step.value : null;

          step.keys.map((key) => {
            this.selectedProductsIds[key] = products[key].map((selectedProduct) => {
              if (!this.selectedRegions[step.value - 1]) {
                this.selectedRegions[step.value - 1] = this.regions.find(region => region.id === selectedProduct.regionId)
              }

              return selectedProduct.productId
            });
          })
        });
      }
    },

    closeModal() {
      this.step = 1;
      this.show = false;
      this.editKey = null;
      this.selectedProductsIds = {
        iot_hub: [],
        dtu: [],
        storage: [],
        backup: [],
        mathematical_function: [],
        other: [],
        dashboard: [],
        digital_twin: []
      }
    },

    changeRegion(region) {
      this.selectedRegions[this.step - 1] = region

      this.steps[this.step - 1].keys.map(key => this.selectedProductsIds[key] = [])
    },

    updateSelectedProductsByType(productsIds, type) {
      this.selectedProductsIds[type] = productsIds;
    },

    updateCheckout() {
      this.steps[this.step - 1].keys.map((key) => {
        this.updateSelectedProducts({
          key,
          selectedProducts: this.selectedProductsIds[key].map((productId) => {
            return {regionId: this.selectedRegions[this.step - 1].id, productId};
          })
        })
      })

      this.closeModal();
    },

    addToCheckout() {
      let selectedProducts = {};

      this.steps.map((step) => {
        step.keys.map((key) => {
          selectedProducts[key] = [];

          this.selectedProductsIds[key].map((productId) => {
            selectedProducts[key].push({regionId: this.selectedRegions[step.value - 1].id, productId})
          })
        })
      });

      this.setSelectedProducts(selectedProducts);
      this.cartSent = false;

      this.closeModal();

      this.$router.push('/checkout');
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/_modalProduct.scss";
</style>
