import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        questions: [],
    },

    mutations: {
        setQuestions: (state, questions) => {
            state.questions = questions;
        },
    },

    actions: {
        async getQuestions({commit}) {
            const response = await http.get(api.questions.index);

            commit('setQuestions', response.data.questions);

            return response.data.questions;
        },
    }
}
