<template>
  <div v-if="!hasSelectedCookies" v-cloak
       class="border rounded border-gray-300 p-4 fixed bottom-4 mx-2 lg:mx-0 lg:right-4 bg-white max-w-lg text-sm max-h-[97%] md:max-h-[90%] overflow-y-auto z-index-9999999999"
  >
    <div class="font-medium text-xl">
      {{ $t('footer.cookies.heading') }}
    </div>
    <p class="mt-3 sm:block"
       :class="{'hidden': !expanded}"
    >
      {{ $t('footer.cookies.introduction') }}
    </p>
    <p class="mt-3 sm:hidden"
       :class="{'hidden': expanded}"
    >
      {{ $t('footer.cookies.introduction').split('.')[0] }}.
      <a href="#"
         class="text-blue-600"
         @click.prevent="expanded = true"
      >{{ $t('readMore') }}</a>
    </p>
    <div class="sm:block"
         :class="{'hidden': !expanded}">
      <div>
        <label class="flex fz14 mt-3 pl-6">
          <div class="pt-1">
            <input type="checkbox"
                   class="checkSquare w-5 h-5 border mr-3 relative cursor-pointer"
                   checked="checked"
                   disabled
            >
          </div>
          <div>
            <div class="font-medium">
              {{ $t('footer.cookies.requiredCookiesHeading') }}
            </div>
            <p class="mt-1">
              {{ $t('footer.cookies.requiredCookiesText') }}
            </p>
          </div>
        </label>

        <label class="cursor-pointer flex fz14 mt-3 pl-6 select-none">
          <div class="pt-1">
            <input v-model="acceptedCookies.performance"
                   type="checkbox"
                   class="checkSquare w-5 h-5 border mr-3 relative cursor-pointer"
                   :value="true"
            >
          </div>
          <div>
            <div class="font-medium">
              {{ $t('footer.cookies.performanceCookiesHeading') }}
            </div>
            <p class="mt-1">
              {{ $t('footer.cookies.performanceCookiesText') }}
            </p>
          </div>
        </label>

        <label class="cursor-pointer flex fz14 mt-3 pl-6 select-none">
          <div class="pt-1">
            <input v-model="acceptedCookies.analytics"
                   type="checkbox"
                   class="checkSquare w-5 h-5 border mr-3 relative cursor-pointer"
                   :value="true"
            >
          </div>
          <div>
            <div class="font-medium">
              {{ $t('footer.cookies.analyticsCookiesHeading') }}
            </div>
            <p class="mt-1">
              {{ $t('footer.cookies.analyticsCookiesText') }}
            </p>
          </div>
        </label>
      </div>
      <p class="mt-3"
         v-html="$t('footer.cookies.privacyLinkText').replaceAll(':privacyPolicy', privacyPolicyLink)"
      ></p>
      <p class="mt-3"
         v-html="$t('footer.cookies.termsLinkText').replaceAll(':terms', termsLink)"
      ></p>
      <div class="mt-3">
        <button class="blue-btn font-medium mr-3 uppercase py-2 px-6 rounded"
                @click.prevent="submitAcceptedCookies"
        >
          {{ $t('footer.cookies.confirmCookiesSettings') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      acceptedCookies: {
        performance: false,
        analytics: false,
      },
      expanded: false,
    }
  },

  computed: {
    ...mapGetters('Cookies', {
      hasSelectedCookies: "hasSelectedCookies",
    }),

    privacyPolicyLink() {
      return '<a href="/privacy-policy" target="_blank" class="font-medium">' + this.$t('footer.cookies.privacyPolicy') + '</a>';
    },

    termsLink() {
      return '<a href="/terms-conditions" target="_blank" class="font-medium">' + this.$t('footer.cookies.terms') + '</a>';
    },
  },

  methods: {
    ...mapActions('Cookies', ['setAcceptedCookies']),

    submitAcceptedCookies() {
      this.setAcceptedCookies(this.acceptedCookies);
    },
  },
}
</script>

<style scoped>
.z-index-9999999999 {
  z-index: 9999999999;
}
</style>
