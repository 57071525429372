<template>
  <div class="language" @mouseleave="dropShow = false">
    <div @click="dropShow = !dropShow" class="flex cursor-pointer">
      <img
          :src="getLanguageFlag($i18n.locale)"
          :alt="this.$i18n.locale"
          class="flag mr-2"
      >
      <img src="../../assets/images/arrowDown.svg" alt="arrow">
    </div>

    <ul class="drop" v-show="dropShow">
      <li
          v-for="lang in languages"
          :key="lang"
      >
        <img
            :src="getLanguageFlag(lang)"
            :alt="lang"
            @click="changeLocale(lang)"
            class="flag"
        >
      </li>

    </ul>
  </div>
</template>

<script>
import {setLocale} from '@vee-validate/i18n';
import http from "@/libs/axios";
import languages from "@/helpers/languages";

export default {
  name: "TheLanguage",
  data() {
    return {
      dropShow: false,
      languages,
    }
  },

  methods: {
    getLanguageFlag(code) {
      try {
        return require(`@/assets/images/languages/${code.toUpperCase()}.svg`)
      } catch (e) {
        return '';
      }
    },

    changeLocale(l) {
      if (this.$i18n.locale !== l) {
        this.$i18n.locale = l
        this.dropShow = !this.dropShow
        setLocale(l);
        http.defaults.headers['X-localization'] = l;
        localStorage.setItem('lang', l);
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/ui/_language.scss";
</style>
