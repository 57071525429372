<template>
  <div class="p-3 bg-white border-2 border-gray-300 md:h-100 overflow-auto">
    <router-link :to="`/articles/${article.slug}`" class="block">
      <img :src="article.image ? url + article.image : defaultImage" alt="" class="object-cover object-center w-full h-40">
    </router-link>
    <div class="md:h-64 grid content-between">
      <div class="mt-6">
        <div class="text-xs mb-2" v-if="tags">
          {{tags}}
        </div>

        <router-link :to="`/articles/${article.slug}`" class="block">
          <h2 class="text-xl font-bold mb-1 text-blue line-clamp-2">
            {{ article.title }}
          </h2>
        </router-link>

        <p class="mt-2 text-sm">
          {{ $trimWords(article.description, 170) }}
          <router-link :to="`/articles/${article.slug}`"
             class="text-primary-700"
          >
            {{ $t('readMore') }}
          </router-link>
        </p>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-3 mt-3 border-t-2 border-gray-300 pt-2 text-sm">
        <div v-if="article.reading_time">
          {{ article.reading_time }} {{ $t('resources.read') }}
        </div>

        <div class="sm:col-span-2">{{ article.date }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleCard",

  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    url: process.env.VUE_APP_ADMIN_URL,
    defaultImage: require('@/assets/images/noImg.jpg')
  }),

  computed: {
    tags() {
      let tags = '';

      if (!this.article.tags || this.article.tags.length === 0) {
        return null;
      }

      this.article.tags.map((tag) => {
        tags += tag.name + ', ';
      });

      return tags.slice(0, -2);
    }
  }
}
</script>

<style lang="scss">
.h-100 {
  height: 28rem;
}
</style>
