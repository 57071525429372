export const setToLocalStorageWithExpiry = (key, value, seconds = null) => {
    const now = new Date()

    const item = {
        value: value,
        expiry: seconds ? now.getTime() + seconds * 1000 : 'never',
    }

    localStorage.setItem(key, JSON.stringify(item))
}

export const  getFromLocalStorageWithExpiry = (key, defaultValue = null) => {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (item.expiry !== 'never' && now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return defaultValue;
    }

    return item.value
}

export const filterProductsByRegion = (products, regionId) => {
    return products.filter((product) => {
        if (!product.visible) {
            return false;
        }

        const region = product.regions.find((region) => region.id === regionId);

        if (region) {
            product.region = region;
            return true;
        }

        return false;
    });
}
