<template>
  <div class="header lg:py-3 lg:sticky lg:top-0 z-30 bg-white">
    <div class="container relative" @mouseleave="showMobile = false">
      <div class="flex flex-row justify-between jus align-content items-center mxm-15">
        <div class="logo flex-initial">
          <router-link :to="'/'">
            <img src="../assets/images/logo.svg" alt="logo">
          </router-link>
        </div>

        <div class="flex-initial grow px-15 menu-block block" :class="{ active: showMobile }">
          <nav class="list-none flex mxm-7">
            <li class="px-7p">
              <router-link class="btn text-blue inline-flex font-semibold border-blue border w-full lg:w-auto"
                           active-class="blue-btn text-white-important"
                           @click="showMobile = false"
                           to="/platform"
              >
                {{ $t('home.menu.Platform') }}
              </router-link>
            </li>
            <li class="px-7p">
              <router-link class="btn text-blue inline-flex font-semibold border-blue border w-full lg:w-auto"
                           active-class="blue-btn text-white-important"
                           @click="showMobile = false"
                           to="/pricing"
              >
                {{ $t('home.menu.Pricing') }}
              </router-link>
            </li>
            <li class="px-7p">
              <router-link class="btn text-blue inline-flex font-semibold border-blue border w-full lg:w-auto"
                           active-class="blue-btn text-white-important"
                           @click="showMobile = false"
                           to="/resources"
              >
                {{ $t('home.menu.Resources') }}
              </router-link>
            </li>
            <li class="px-7p">
              <router-link class="btn text-blue inline-flex font-semibold border-blue border w-full lg:w-auto"
                           active-class="blue-btn text-white-important"
                           @click="showMobile = false"
                           to="/partners"
              >
                {{ $t('home.menu.Partners') }}
              </router-link>
            </li>
            <li class="px-7p">
              <router-link class="btn text-blue inline-flex font-semibold border-blue border w-full lg:w-auto"
                           active-class="blue-btn text-white-important"
                           @click="showMobile = false"
                           to="/training"
              >
                {{ $t('home.menu.Training') }}
              </router-link>
            </li>
            <li class="px-7p">
              <router-link class="btn text-blue inline-flex font-semibold border-blue border w-full lg:w-auto"
                           active-class="blue-btn text-white-important"
                           @click="showMobile = false"
                           to="/about"
              >
                {{ $t('home.menu.AboutUs') }}
              </router-link>
            </li>
            <li class="px-7p flex justify-center">
              <TheLanguage class="lg:hidden inline-block"></TheLanguage>
            </li>
          </nav>
        </div>

        <div class="flex-initial px-15 ">
          <div class="flex mx-[-15px] sm:mx-0 items-center">
            <div class="xl:px-[15px] text-blue flex items-center">
              <router-link :to="$freeTrialUrl()"
                           class="btn inline-flex border-blue border ml-3"
                           active-class="blue-btn text-white-important"
              >
                <span class="hidden sm:inline lg:hidden">{{ $t('home.menu.GetDemo') }}</span>
                <span class="hidden lg:inline">{{ $t('home.menu.GetDemoShort') }}</span>
                <PlayIcon class="h-6 w-6 sm:hidden"/>
              </router-link>
              <router-link v-if="!isLoggedIn" to="/login" class="btn blue-btn inline-flex cursor-pointer ml-3">
                <span class=" hidden sm:inline lg:hidden xl:inline">{{ $t('home.menu.signIn') }}</span>
                <CursorClickIcon class="h-6 w-6 sm:hidden lg:inline xl:hidden"/>
              </router-link>
              <a v-if="isLoggedIn" href="https://login.easas.net" class="btn blue-btn inline-flex cursor-pointer ml-3" target="_blank">
                <span class="hidden sm:inline lg:hidden xl:inline text-green-500">{{ $t('home.menu.GoToPlatform') }}</span>
                <span class="hidden lg:inline xl:hidden whitespace-nowrap text-green-500">{{ $t('home.menu.GoToPlatformShort') }}</span>
                <CursorClickIcon class="h-6 w-6 sm:hidden"/>
              </a>
              <div class="flex items-center" v-if="isLoggedIn" @mouseleave="userMenu = false">
                <div class="user-menu relative p-3 pl-7">
                  <UserIcon class="h-7 w-7 cursor-pointer" @click="userMenu = !userMenu"/>
                  <ul :class="{ active: userMenu }">
                    <li>
                      <router-link :to="{name: 'ProfileView'}" @click="userMenu = false">
                        <UserIcon class="w-5 h-5"/>
                        {{ $t('home.user-menu.profile') }}
                      </router-link>
                    </li>
                    <li v-if="authUser.hasActiveOrders">
                      <a href="http://login.easas.net/" target="_blank">
                        <CogIcon class="w-5 h-5"/>
                        EA-SAS
                      </a>
                    </li>
                    <li>
                      <router-link :to="'/management'" @click="userMenu = false">
                        <AdjustmentsIcon class="w-5 h-5"/>
                        {{ $t('home.user-menu.control') }}
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="'/support'" @click="userMenu = false">
                        <SpeakerphoneIcon class="w-5 h-5"/>
                        <span>{{ $t('home.user-menu.support') }}</span>
                        <span id="countRepliesUnread" v-if="countRepliesUnread" class="count-unread">{{ countRepliesUnread }}</span>
                      </router-link>
                    </li>
                    <li @click="logout">
                      <div>
                        <LogoutIcon class="w-5 h-5"/>
                        {{ $t('home.user-menu.exit') }}
                      </div>
                    </li>
                  </ul>
                  <i :class="{ active: userMenu }" class="opacity-75 hover:opacity-100" @click="userMenu = false">
                    <XIcon class="w-4 h-4"/>
                  </i>
                </div>
              </div>
            </div>

            <div class="ml-3 mr-3 lg:hidden flex items-center cursor-pointer burger-btn relative"
                 :class="{ active: showMobile }"
                 @click="showMobile = !showMobile"
            >
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CursorClickIcon,
  PlayIcon,
  UserIcon,
  XIcon,
  LogoutIcon,
  AdjustmentsIcon,
  SpeakerphoneIcon,
  CogIcon,
} from '@heroicons/vue/outline'
import {mapActions, mapGetters, mapState} from "vuex";
import {useToast} from "vue-toastification";

export default {
  name: "TheHeaderNew",

  components: {
    CursorClickIcon,
    PlayIcon,
    UserIcon,
    XIcon,
    LogoutIcon,
    AdjustmentsIcon,
    SpeakerphoneIcon,
    CogIcon,
  },

  setup() {
    const toast = useToast();

    return {toast}
  },

  created() {
    if (this.isLoggedIn) {
      this.getCountRepliesUnread();
    }
  },

  computed: {
    ...mapState('Support', ['countRepliesUnread']),
    ...mapGetters('Auth', {
      isLoggedIn: 'isLoggedIn',
      authUser: 'getAuthUser',
    }),
  },

  data() {
    return {
      showMobile: false,
      userMenu: false,
    }
  },

  methods: {
    ...mapActions('Support', ['getCountRepliesUnread']),
    ...mapActions('Auth', {
      logoutUser: 'logout',
    }),

    logout() {
      this.$router.push({name: 'home'});

      this.logoutUser().then(() => {
        this.toast.success(this.$t('login.logoutSuccessfully'));
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/components/_theHeader.scss";
</style>
