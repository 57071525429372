import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        articles: [],
    },

    mutations: {
        setArticles: (state, articles) => {
            state.articles = articles;
        },

        setArticle: (state, article) => {
            state.article = article;
        },

        clearArticle: (state) => {
            state.article = null;
        },
    },

    actions: {
        async getArticles({commit}) {
            const response = await http.get(api.articles.index);

            commit('setArticles', response.data.articles);

            return response.data.articles;
        },

        async getArticle({commit}, article) {
            const response = await http.get(api.articles.show.replace('{articleId}', article));

            commit('setArticle', response.data.article);

            return response.data.article;
        },

        async clearArticle({commit}) {
            commit('clearArticle');
        },
    }
}
