import axios from 'axios'
import store from '@/store'
import i18n from '@/libs/i18n/index.js'
import {getFromLocalStorageWithExpiry} from "@/helpers/utils";

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-localization': i18n.global.locale
};

let accessToken = getFromLocalStorageWithExpiry('accessToken');

if(accessToken) {
    headers['Authorization'] = 'Bearer ' + accessToken;
}

const http = axios.create({
    baseURL: `${process.env.VUE_APP_ADMIN_URL}/api`,
    headers,
    withCredentials: true,
})

http.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
        store.dispatch('Auth/clearSession').then(() => {
            window.location = '/login';
        });
    }

    return Promise.reject(error);
})

export default http
