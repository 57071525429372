import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        ticket: [],
        tickets: [],
        replies: [],
        ticketStatus: '',
        nextPageUrl: '',
        prevPageUrl: '',
        countRepliesUnread: 0,
        currentPage: 0,
        countPages: 0,
    },

    mutations: {
        setTickets: (state, tickets) => {
            state.tickets = tickets.data;
            state.currentPage = tickets.current_page;
            state.nextPageUrl = tickets.next_page_url;
            state.prevPageUrl = tickets.prev_page_url;
            state.countPages = Math.ceil(tickets.total / tickets.per_page);
        },
        setTicketData: (state, ticket) => {
            state.ticket = ticket.ticket;
            state.replies = ticket.replies;
            state.ticketStatus = ticket.ticket ? ticket.ticket.status : 'new';
        },
        setCountRepliesUnread: (state, data) => {
            state.countRepliesUnread = data.count;
        },
    },

    actions: {
        async getTicketList({commit}, page) {
            var params = {page: page};
            const response = await http.get(api.support.index, {params});
            commit('setTickets', response.data.tickets);

            return response.data.tickets;
        },

        async getTicketData({commit}, ticketId) {
            const response = await http.get(api.support.show.replace('{ticketId}', ticketId));
            commit('setTicketData', response.data);

            return response.data;
        },

        async sendTicketData(_, params) {
            const response = await http.post(api.support.addTicket, params);

            return response.data.message;
        },

        async replyToTicket(_, params) {
            const response = await http.post(api.support.reply, params);

            return response.data.message;
        },

        async getCountRepliesUnread({commit}) {
            const response = await http.post(api.support.countUnread);
            commit('setCountRepliesUnread', response.data);
            return response.data;
        },
    }
}
