import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        videos: [],
        video: null,
    },

    mutations: {
        setVideos: (state, videos) => {
            state.videos = videos;
        },

        setVideo: (state, video) => {
            state.video = video;
        },

        clearVideo: (state) => {
            state.video = null;
        },
    },

    actions: {
        async getVideos({commit}) {
            const response = await http.get(api.videos.index);

            commit('setVideos', JSON.parse(JSON.stringify(response.data.videos)));

            return JSON.parse(JSON.stringify(response.data.videos));
        },

        async getVideo({commit}, video) {
            const response = await http.get(api.videos.show.replace('{videoId}', video));

            commit('setVideo', response.data.video);

            return response.data.video;
        },

        async clearVideo({commit}) {
            commit('clearVideo');
        },
    }
}
