import http from "@/libs/axios"
import api from '@/api'
import {getFromLocalStorageWithExpiry, setToLocalStorageWithExpiry} from "@/helpers/utils";

export default {
    namespaced: true,

    state: {
        authUser: getFromLocalStorageWithExpiry('authUser'),
    },

    getters: {
        getAuthUser: state => state.authUser,
        isLoggedIn: state => state.authUser !== null,
    },

    mutations: {
        setAuthUser: (state, authUser) => {
            if (!authUser.phone_prefix) authUser.phone_prefix = '+370';
            if (!authUser.country) authUser.country = 'LT';

            setToLocalStorageWithExpiry('authUser', authUser, process.env.VUE_APP_SESSION_TIME_IN_SECONDS);
            state.authUser = authUser;
        },

        unsetAuthUser: (state) => {
            state.authUser = null;
        }
    },

    actions: {
        commitResponse({commit}, response) {
            setToLocalStorageWithExpiry('accessToken', response.data.accessToken, process.env.VUE_APP_SESSION_TIME_IN_SECONDS);

            http.defaults.headers['Authorization'] = 'Bearer ' + response.data.accessToken;

            commit('setAuthUser', response.data.authUser);
        },

        clearSession({commit}) {
            localStorage.removeItem('authUser');
            localStorage.removeItem('accessToken');
            sessionStorage.clear();

            commit('unsetAuthUser');

            delete http.defaults.headers['Authorization'];
        },

        async login({dispatch}, code) {
            const response = await http.get(api.auth.login, {
                params: {
                    code: code,
                }
            });

            dispatch('commitResponse', response);
        },

        async register({dispatch}, user) {
            const response = await http.post(api.auth.register, user);

            dispatch('commitResponse', response);
        },

        async logout({dispatch}) {
            await http.post(api.auth.logout);

            dispatch('clearSession');
        },

        async passwordRecovery(_, email) {
            const response = await http.post(api.auth.passwordRecovery, email);

            return response.data.message;
        },

        async passwordReset(_, data) {
            const response = await http.post(api.auth.passwordReset, data);

            return response.data.message;
        },
    },
}
