<template>
  <div class="w-full mt-6 psevdo-table">
    <div class="thead flex text-center items-center">
      <div class="w-1/3 sm:w-1/4 lg:px-6 cursor-pointer th" @click="updateSort('cpu_hours_per_day')"
           :class="{'up': this.sortBy.column === 'cpu_hours_per_day' && this.sortBy.ascending === 'DESC'}">
          <span class="inline-flex">
            {{ $t('pythonPage.cpuHoursPerDay') }}
            <img src="../../assets/images/arrowDown.svg">
          </span>
      </div>
      <div class="w-1/3 sm:w-1/4 cursor-pointer th" @click="updateSort('price')"
           :class="{'up': this.sortBy.column === 'price' && this.sortBy.ascending === 'DESC'}">
          <span class="inline-flex">
            {{ $t('digitalPage.priceMonth') }}
            <img src="../../assets/images/arrowDown.svg">
          </span>
      </div>
      <div class="grow w-1/4" v-if="showOnModal !== 'finish'">

      </div>
    </div>
    <div class="tbody">
      <div
          v-for="product in sortedProducts"
          :key="product.id"
          class="tr flex items-center relative"
          :class="{ active: product.is_popular}"
      >
        <div class="hidden absolute spec-offer" v-if="showOnModal!=='finish'">{{ $t('digitalPage.recommended') }}</div>
        <div class="brd-right td w-1/3 sm:w-1/4 lg:px-6">{{ product.attribute.cpu_hours_per_day }}</div>
        <div class="td w-1/3 sm:w-1/4">
          {{ product.region.pivot.price > 0 ? $filters.priceWithSpace(product.region.pivot.price) + '€' : $t('ui.free') }}
        </div>
        <div class="td grow w-12 sm:w-1/2" v-if="showOnModal !== 'finish'">
          <div v-if="showOnModal">
            <label class="cursor-pointer flex mb-6 fz14 border-top customRadio">
              <input
                  type="radio"
                  class="hidden"
                  :checked="selectedProductIds.includes(product.id)"
                  :value="product.id"
                  @change="updateSelectedProductId($event.target.value)"
              >
              <span class="checkСircle w-5 h-5 border mr-3 relative"></span>
            </label>
          </div>
          <div v-else>
            <button class="btn hidden blue-btn3 lg:inline-flex mr-3" @click="showModal(product.description)">
              {{ $t('ourProduct.learnMore') }}
            </button>

            <button v-if="!showOnModal" @click="$vfm.show('example')" class="btn blue-btn inline-flex">
              {{ $t('ui.getStarted') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PythonTablet",

  data() {
    return {
      sortBy: {
        column: 'price',
        ascending: 'ASC'
      }
    }
  },

  props: {
    products: {
      type: Array,
      required: true
    },

    showOnModal: {
      type: Boolean,
      default: false
    },

    selectedProductIds: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    sortedProducts() {
      let products = this.products;

      return products.sort((a, b) => {
        return this.sortBy.ascending === 'ASC' ?
            this.sortBy.column === 'price' ? a.region.pivot.price - b.region.pivot.price :
                a.attribute[this.sortBy.column] - b.attribute[this.sortBy.column] :
            this.sortBy.column === 'price' ? b.region.pivot.price - a.region.pivot.price :
                b.attribute[this.sortBy.column] - a.attribute[this.sortBy.column];
      });
    }
  },

  methods: {
    updateSort(column) {
      if (this.sortBy.column !== column) {
        this.sortBy.column = column;
      }

      this.sortBy.ascending = this.sortBy.ascending === 'ASC' ? 'DESC' : 'ASC';
    },

    showModal(description) {
      this.$vfm.show('modalMore', {description})
    },

    updateSelectedProductId(productId) {
      this.$emit('updateSelectedProductId', [parseInt(productId)], 'mathematical_function')
    },
  }
}
</script>
