<template>
  <div class="grid grid-cols-1 lg:grid-cols-12 px-12">
    <div class="lg:col-span-5 pt-6 order-3 lg:order-1">
      <div class="bg-gray-200 text-gray-500 py-6">
        <div class="flex items-center">
          <div class="pl-8 pr-4">{{ $t('calculatorForm.pessimisticScenario') }}</div>
          <div class="h-px bg-gray-400 flex-grow"></div>
        </div>

        <div v-if="typeof results.output_fields.MAIN !== 'undefined'"
             class="px-8"
        >
          <div v-for="outputField in results.output_fields.MAIN.pessimistic" :key="outputField.key"
               class="mt-6"
          >
            <div class="flex items-end font-medium">
              <div class="text-5xl mr-2">{{ outputField.value }}</div>
              <div class="text-2xl">{{ outputField.units }}</div>
            </div>
            <div>{{ outputField.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-span-7 order-1 lg:order-2">
      <div class="bg-primary-700 text-white py-6 h-full">
        <div class="flex items-center px-8">
          <div class="pr-4">{{ $t('calculatorForm.optimisticScenario') }}</div>
          <div class="h-px bg-white flex-grow"></div>
        </div>

        <div v-if="typeof results.output_fields.MAIN !== 'undefined'"
             class="px-8"
        >
          <div v-for="outputField in results.output_fields.MAIN.optimistic" :key="outputField.key"
               class="mt-6"
          >
            <div class="flex items-end font-medium">
              <div class="text-6xl mr-2">{{ outputField.value }}</div>
              <div class="text-2xl">{{ outputField.units }}</div>
            </div>
            <div>{{ outputField.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="lg:col-span-5 pb-6 order-4 lg:order-3">
      <div class="bg-gray-200 text-gray-500 py-6 px-8">
        <template v-for="secondaryGroup in secondaryOutputFields">
          <div v-for="outputField in secondaryGroup.pessimistic" :key="outputField.key"
               class="mt-6"
          >
            <div>{{ outputField.name }}:</div>
            <div class="flex items-end font-medium">
              <div class="text-2xl mr-2">{{ outputField.value }}</div>
              <div class="text-lg">{{ outputField.units }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="lg:col-span-7 bg-primary-700 text-white py-6 px-8 order-2 lg:order-4">
      <template v-for="secondaryGroup in secondaryOutputFields">
        <div v-for="outputField in secondaryGroup.optimistic" :key="outputField.key"
             class="mt-6"
        >
          <div>{{ outputField.name }}:</div>
          <div class="flex items-end font-medium">
            <div class="text-2xl mr-2">{{ outputField.value }}</div>
            <div class="text-lg">{{ outputField.units }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['results'],

  computed: {
    secondaryOutputFields() {
      // eslint-disable-next-line no-unused-vars
      const {MAIN, ...secondary} = this.results.output_fields;

      return secondary;
    },
  },
}
</script>
