<template>
  <div class="mb-14">
    <router-link :to="$freeTrialUrl()" class="blue-btn inline-flex btn mr-3 uppercase"><span>{{ $t('home.menu.GetDemo') }}</span></router-link>
    <router-link v-if="!isLoggedIn"
                 to="/register"
                 class="btn inline-flex uppercase border border-blue md:inline-flex btn-free cursor-pointer mt-1 md:mt-0"
    >
      <span>{{ $t('home.homeView.start') }}</span>
    </router-link>
    <button v-if="isLoggedIn"
            @click.prevent="$vfm.show('example')"
            class="btn inline-flex uppercase border border-blue md:inline-flex btn-free cursor-pointer mt-1 md:mt-0"
    >
      {{ $t('home.homeView.start') }}
    </button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BtnOnHome",

  computed: {
    ...mapGetters('Auth', {
      isLoggedIn: 'isLoggedIn'
    }),
  },
}
</script>
