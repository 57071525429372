import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        caseStudies: [],
        caseStudy: null,
    },

    mutations: {
        setCaseStudies: (state, caseStudies) => {
            state.caseStudies = caseStudies;
        },

        setCaseStudy: (state, caseStudy) => {
            state.caseStudy = caseStudy;
        },

        clearCaseStudy: (state) => {
            state.caseStudy = null;
        },
    },

    actions: {
        async getCaseStudies({commit}) {
            const response = await http.get(api.caseStudies.index);

            commit('setCaseStudies', JSON.parse(JSON.stringify(response.data.caseStudies)));

            return JSON.parse(JSON.stringify(response.data.caseStudies));
        },

        async getCaseStudy({commit}, caseStudy) {
            const response = await http.get(api.caseStudies.show.replace('{caseStudyId}', caseStudy));

            commit('setCaseStudy', response.data.caseStudy);

            return response.data.caseStudy;
        },

        async clearCaseStudy({commit}) {
            commit('clearCaseStudy');
        },
    }
}
