import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        tags: [],
    },

    mutations: {
        setTags: (state, tags) => {
            state.tags = tags;
        },
    },

    actions: {
        async getTags({commit}) {
            const response = await http.get(api.tags.index);

            commit('setTags', response.data.tags);

            return response.data.tags;
        },
    }
}
