<template>
  <div>
    <div class="lg:-mt-14 flex flex-wrap justify-center xs:justify-start mb-6">
      <button v-for="option in options" :key="option.value"
              class="border border-primary-800 rounded-md py-3 px-2 uppercase mb-2 w-full xs:py-1 xs:w-auto xs:mr-2 max-w-mobile-btn xs:max-w-none"
              :class="{
                'text-green-500 bg-primary-800': _selectedCalculatorId === option.value,
                'bg-white text-primary-800 hover:bg-primary-800 hover:text-white': _selectedCalculatorId !== option.value
              }"
              @click.prevent="_selectedCalculatorId = option.value"
      >
        {{ option.label }}
      </button>
    </div>

    <Form as="form" @submit="submitForm" ref="calculatorForm">
      <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-8">
        <template v-if="selectedCalculator !== null">
          <div v-for="input in selectedCalculator.input_fields" :key="input.id">
            <Field v-if="input.options.length > 0"
                   class="input-block relative"
                   as="div"
                   type="select"
                   :name="input.name"
                   rules="required"
            >
              <p class="name">{{ input.name }}</p>
              <div class="flex items-center">
                <select class="standard cursor-pointer"
                        v-model="inputs[input.id]"
                        @input="inputs[input.id] = $event.target.value"
                >
                  <option v-for="inputOption in input.options" :key="inputOption.value" :value="inputOption.value">
                    {{ inputOption.value }}
                  </option>
                </select>
                <div v-if="input.units !== null && input.units.length > 0"
                     class="ml-2 w-1/3 text-gray-500"
                >
                  {{ input.units }}
                </div>
              </div>
              <ErrorMessage as="div" :name="input.name" class="errorMessageInitialPosition"/>
              <p v-if="input.description !== null" class="text-sm text-gray-400 mt-1 pr-2">{{ input.description }}</p>
            </Field>
            <Field v-else
                   class="input-block relative"
                   as="div"
                   :name="input.name"
                   rules="required"
            >
              <p class="name">{{ input.name }}</p>
              <div class="flex items-center">
                <input v-model="inputs[input.id]"
                       class="standard"
                       style="margin-bottom: 0;"
                       :type="input.field_type === 'NUMBER' ? 'number' : 'text'"
                />
                <div v-if="input.units !== null && input.units.length > 0"
                     class="ml-2 w-1/3 text-gray-500"
                >
                  {{ input.units }}
                </div>
              </div>
              <ErrorMessage as="div" :name="input.name" class="errorMessageInitialPosition"/>
              <p v-if="input.description !== null" class="text-sm text-gray-400 mt-1 pr-2">{{ input.description }}</p>
            </Field>
          </div>
        </template>
      </div>

      <div v-if="typeof serverErrors !== 'undefined' && serverErrors !== null && serverErrors.length > 0">
        <ul class="list-disc text-red-500 px-5 mb-6">
          <template v-for="(err, key) in serverErrors" :key="key">
            <li v-for="(error, key2) in err" :key="key2">
              {{ error }}
            </li>
          </template>
        </ul>
      </div>

      <div class="flex md:items-center justify-center md:justify-start">
        <ui-button :class="{'opacity-70': submitting}"
                   :disabled="submitting"
                   :arrow="true"
        >
          {{ submitting ? $t('calculatorForm.submitting') : $t('calculatorForm.submit') }}
        </ui-button>

        <span v-if="errorMessage !== null" class="mt-4 md:ml-4 md:mt-0 text-error">
          {{ errorMessage }}
        </span>
      </div>
    </Form>

    <CalculatorEmailForm ref="calculatorEmailForm"
                         @email-set="emailSetListener"
    />

    <div v-if="results !== null">
      <div class="relative flex items-center my-12">
        <div class="text-3xl mr-6">{{ $t('calculatorForm.yourSavings') }}</div>
        <div class="flex-grow border-t border-gray-400"></div>
      </div>

      <CalculatorResults :results="results" />
    </div>
  </div>
</template>

<script>

import {ErrorMessage, Field, Form} from "vee-validate";
import http from "@/libs/axios";
import api from "@/api";
import CalculatorResults from "@/components/Forms/Calculator/CalculatorResults";
import CalculatorEmailForm from "@/components/Forms/Calculator/CalculatorEmailForm.vue";

export default {
  name: "CalculatorForm",

  props: ['calculators', 'selectedCalculatorId'],

  components: {
    CalculatorEmailForm,
    CalculatorResults,
    Form,
    ErrorMessage,
    Field,
  },

  data() {
    return {
      inputs: {},
      serverErrors: [],
      results: null,
      submitting: false,
      sendingEmail: false,
      sentEmail: false,
      errorMessage: null,
      usedInputs: null,
      usedCalculatorId: null,
      userEmail: null,
      shouldSendOverEmail: null,
    }
  },

  computed: {
    options() {
      return this.calculators.map((calculator) => {
        return {
          label: calculator.name,
          value: calculator.id,
        }
      });
    },

    _selectedCalculatorId: {
      get() {
        return this.selectedCalculatorId;
      },
      set(value) {
        this.$emit('update:selectedCalculatorId', value);
      },
    },

    selectedCalculator() {
      if (this.selectedCalculatorId === null) {
        return null;
      }

      const calculator = this.calculators.find(calculator => calculator.id === this.selectedCalculatorId);

      if (typeof calculator === 'undefined') {
        return null;
      }

      return calculator;
    },

    emailSentSuccessMessage() {
      return this.$t('calculatorForm.sentOverEmail').replaceAll(':email', this.userEmail);
    },
  },

  watch: {
    selectedCalculator: {
      deep: true,
      handler() {
        this.setDefaultInputValues();
      },
    },
  },

  methods: {
    submitForm() {
      if (this.submitting) {
        return;
      }

      this.errorMessage = null;

      if (this.userEmail === null) {
        this.$refs.calculatorEmailForm.show = true;

        return;
      }

      this.$refs.calculatorForm.validate().then(validated => {
        if (validated.valid) {
          this.submitting = true;
          this.serverErrors = [];
          this.results = null;

          this.usedCalculatorId = this.selectedCalculator.id;
          this.usedInputs = JSON.parse(JSON.stringify(this.inputs));
          this.sentEmail = false;

          http.post(api.calculators.submit.replaceAll('{calculatorId}', this.selectedCalculator.id), {
            email: this.userEmail,
            inputs: this.inputs,
          }).then(response => {
            if (response.data.results.request_parameters_valid) {
              this.results = response.data.results;
            } else {
              const invalidFields = response.data.results.invalid_fields;

              this.serverErrors = invalidFields;

              const errorKeysNotInInputFields = Object.keys(invalidFields).filter(errorKey => {
                return typeof this.selectedCalculator.input_fields.find((input) => {
                  return input.key === errorKey;
                }) === 'undefined';
              });

              if (errorKeysNotInInputFields.length > 0) {
                this.errorMessage = invalidFields[errorKeysNotInInputFields[0]];
              } else {
                this.errorMessage = response.data.results.message ?? null;
              }
            }

            this.submitting = false;
          });

          if (this.shouldSendOverEmail) {
            this.sendOverEmail();
          }
        }
      })
    },

    sendOverEmail() {
      if (this.sendingEmail) {
        return;
      }

      this.sendingEmail = true;
      this.sentEmail = false;

      http.post(api.calculators.sendEmail.replaceAll('{calculatorId}', this.usedCalculatorId), {
        email: this.userEmail,
        inputs: this.usedInputs,
      }).then(() => {
        this.sentEmail = true;
        this.sendingEmail = false;
      });
    },

    setDefaultInputValues() {
      if (this.selectedCalculator !== null) {
        let inputs = {};

        this.selectedCalculator.input_fields.forEach(field => {
          inputs[field.id] = null;
        });

        this.inputs = inputs;
      }
    },

    emailSetListener(data) {
      this.userEmail = data.email;
      this.shouldSendOverEmail = data.sendOverEmail;

      this.submitForm();
    },
  },

  mounted() {
    this.setDefaultInputValues();
  },
}
</script>
