<template>
  <button class="fixed flex items-center justify-center bg-primary-800 uppercase text-green-500 font-medium border-2 border-primary-800 hover:text-buttons-primary hover:bg-white right-3 bottom-28 rounded-full w-[60px] h-[60px] sm:w-auto sm:h-auto sm:px-8 sm:py-4 sm:space-x-4 sm:rounded sm:-right-14 sm:bottom-52 sm:transform sm:-rotate-90"
          @click="openModal()"
          :style="{'bottom': bottomPosition, 'right': rightPosition}"
          ref="button"
  >
    <span class="hidden sm:inline-block">
      {{ $t('newsletter.newsletter') }}
    </span>

    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAABQCAMAAADMbnx5AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAQY2FOdgAAAVIAAAC7AAAAWQAAADqe0W6oAAAACXBIWXMAAB2HAAAdhwGP5fFlAAAAM1BMVEVHcEyw4NDQ495m2LFW1qoN1JGU3cP88PxE1qT04O5827ka1JQo1Zoe1Zc61qBI3KkB04uxmNBFAAAAEHRSTlMAOyeTrP5YCsMYdvnj8NhpoBa5XgAABE9JREFUaN6tmtmShCAMRVtkERTh/7920Fl6kRtyrennTnlCdsjD+wf1i2niBIInBc5f2XygsJY9UQLB7je4Sl1nQ2HVleIKtu4p8li1LobCqi5xWE3zcAOrbp7CqqudKKzqrLmBVbP6mE+spojakCdWdbu/gdXEJgqrHZhhsA5DRh6rumWisNR2+cXiuH6xqjJe/rC0ivxhtQP2N7B06jyxjkgJDNbhYIHHanaZKKy6Kg7sBavqU94rVrPLUJ03rGZ5T2HVrEwtb1jtMyNDfmCNDfmOdYR84LGaXQyFNcwUH1jVqVL+J9YowC5YI4FPrMOQhsdq5yXZ5Yo18Mgrlia0rlhHZYkMVhMQPLKDpcgUPSxJ/y6WpH8P6yz2gcY6Uj6FdegfGaxRagFYDgU+wGpcwPIIS24Opr0vhUpRSBkKmH4vvwGBTWqLp8WBz/QdLPgFcVmOy4ndpJmR/rlfwYwFiqBI8UhzMSLDvxnSAYEIBbJU7IytlCGj3zm7QAG5dkFDOtqQIPAn5JFijQh+hYbsSsW5ogDznEWcNA8HwS6GczB0wPMKHUzI+JPN8Jgf/QBDEdyvLLFsOOUJYH4jMyVMrUD/mJBHinMn5uoHGM7ga6IqUXOVdCeDIUPCAMtzP1PMG3QwqRShwK8gIRvEVUEpFjSXmlbBX9ja3bdLtFARf4eLLcWwds0OpjwpUyykgxksMP1LyP/aZeV6ipBI/W8akraL4JF9y8NuSrwCLJnjwgLowgmmfNnByAwWoEeiq9kJeorU68AOAfhXxP41UcMKzHhSP9V0D/3wXbmP4PBFw3MQqMBUAAeV2ssRQchCFRdHGIgOdEY+sxaHAgUaMMEgQR+BAoV0ETyimYX1XVzeDRnlNtBUsLfBYyMoPTvXDJz2gDL9AIENVPMSUHdwyAaYF1auJsSZiUDpqkBot3DUAreCU1YFIQsjsOLxGnc0FnmJoxJJ8Jl3Kzxg9WUMVH0rgbuEwZM1nC5RC4QjEI1JO7yyMvQ1F3BF3CrDGZHtlaS8AK+GyEqADYivIAI0IPjIZLl+IXClfOglYMjf2OEIX29F3q36MpJbmX9xkR8v6WNtXdUDbjDA3byxMJHA5xLcJIKOGqdQS/YL0q1DcdzxxoUcDAIaCrKP9CsGvKBDWNAe6O4oJ/5xZSOfooRhGGAN3sgK0b9BLOkOqIuVR2/PhXPFLpYo0MMa71oV8ng7WPIregdLsaZQSJkL1mh55ILlNFsg5XJ3zS0cuGSohQPxxRlhZXY9Iw83zD6wFt2eTVH0CxgrjwXesZR7T29Ymh2uVyzNTs4bVlbvShVdmPewdDuYL1j6haQnVtYZ/Ym16ezxxGI2Iwul+gvWQi7hcVuLRe8lr1j6jbofLG418AdLvxf6jeX0G7HfWJnbBT6xVmK99cRivOTEoveHizrDPbEoL2lYmV2GPbA4o8fFzeSq9eof9M8n7v8xkV4S0g2qLyDhH+WJ3coaAAAAAElFTkSuQmCC" class="w-10 hidden sm:inline-block">

    <svg class="w-10 h-10 sm:hidden"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
    </svg>
  </button>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NewsletterButton",

  data() {
    return {
      bottomPosition: null,
      rightPosition: null,
    }
  },

  methods: {
    ...mapActions('Newsletter', ['openModal']),

    windowResizeHandler() {
      const tidioChatEl = document.getElementById('tidio-chat-iframe');

      if (typeof tidioChatEl !== 'undefined' && tidioChatEl !== null) {
        const boundingRect = tidioChatEl.getBoundingClientRect();
        const tidioDocument = tidioChatEl.contentWindow.document;

        const tidioKey = tidioDocument.body.classList.contains('mobile')
          ? 'tidioMobile'
          : 'tidioDesktop';

        const responsiveKey = window.innerWidth < 670 ? 'mobile' : 'desktop';

        const paddingSettings = {
          mobile: {
            tidioMobile: {
              right: 17,
              bottom: 3,
            },
            tidioDesktop: {
              right: 17,
              bottom: 3,
            }
          },
          desktop: {
            tidioMobile: {
              right: -64,
              bottom: 80,
            },
            tidioDesktop: {
              right: null,
              bottom: null,
            },
          },
        }

        const padding = paddingSettings[responsiveKey][tidioKey];

        this.rightPosition = padding.right !== null
          ? ((window.innerWidth - boundingRect.right + padding.right) + 'px')
          : null;

        this.bottomPosition = padding.bottom !== null
          ? ((window.innerHeight - boundingRect.top + padding.bottom) + 'px')
          : null;
      } else {
        this.rightPosition = null;
        this.bottomPosition = null;
      }
    },

    onTidioChatApiReady() {
      this.windowResizeHandler();

      setTimeout(this.windowResizeHandler, 5000);
    },
  },

  created() {
    window.addEventListener("resize", this.windowResizeHandler);

    this.windowResizeHandler();

    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", this.onTidioChatApiReady);
    } else {
      document.addEventListener("tidioChat-ready", this.onTidioChatApiReady);
    }
  },

  unmounted() {
    window.removeEventListener("resize", this.windowResizeHandler);
  }
}
</script>
