import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    actions: {
        async show({commit}) {
            const response = await http.get(api.accountSetting.show);

            const user = response.data.me;

            if (user.newsletter_subscribed) {
                commit('Newsletter/setSubscribed', true, {root: true});
            }

            commit('Auth/setAuthUser', user, {root: true});
        },

        async update({commit}, params) {
            const response = await http.put(api.accountSetting.update, params);

            commit('Auth/setAuthUser', response.data.user, {root: true});
        }
    },
}
