<template>
  <vue-final-modal v-model="show"
                   :ssr="false"
                   name="oneTouch"
                   classes="flex justify-center items-center overflow-auto "
                   content-class=" max-h-full rounded"
                   ref="oneTouch">
    <div class="oneTouch box-border modal-product bg-white p-3 md:px-8 md:py-5 my-20">
      <div class="close text-right">
        <XIcon class="h-6 w-6 inline text-grey cursor-pointer" @click="show = false"></XIcon>
      </div>
      <GetInTouch></GetInTouch>
    </div>
  </vue-final-modal>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'
import GetInTouch from "@/components/Forms/GetInTouch";

export default {
  name: "ModalOneTouch",
  data(){
    return{
      show: false
    }
  },

  components: {
    GetInTouch,
    XIcon
  },
}
</script>

<style scoped lang="scss">
  .oneTouch{
    width: 640px;
    @media screen and (max-width: 660px) {
      width: calc(100vw - 20px);
    }
  }
</style>
