<template>
  <div v-if="sortedProducts.length > 0"
       class="w-full mt-6 psevdo-table 1112"
  >
    <div v-for="productGroupId in productGroupIds" :key="productGroupId"
         :class="{'px-4 py-2 rounded-md shadow-extended mb-4': productGroupId !== null}"
    >
      <div v-if="productGroupId !== null"
           class="flex flex-wrap sm:pl-8 py-4"
      >
        <div class="flex flex-grow items-center font-medium text-lg cursor-pointer select-none justify-center sm:justify-start"
             @click="toggleExpandGroup(productGroupId)"
        >
          <svg class="w-8 h-8 text-gray-300 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>

          {{ productGroups[productGroupId].name }}
        </div>

        <div class="flex justify-center w-full mt-2 sm:mb-0 sm:w-auto">
          <button v-if="!showOnModal && productGroups[productGroupId].content !== null && productGroups[productGroupId].content.length > 0"
                  @click="showModal(productGroups[productGroupId].content)"
                  class="btn blue-btn3 inline-flex mx-1.5"
          >
            {{ $t('ourProduct.learnMore') }}
          </button>

          <button v-if="!showOnModal" @click="$vfm.show('example')" class="p-2 rounded btn blue-btn inline-flex mx-1.5">
            {{ $t('ui.getStarted') }}
          </button>
        </div>
      </div>

      <div v-show="isGroupExpanded(productGroupId)" class="tbody">
        <div
            v-for="product in filterProductsByProductGroup(sortedProducts, productGroupId)"
            :key="product.id"
            class="tr flex flex-wrap sm:flex-nowrap items-center relative"
            :class="{ active: product.is_popular}"
        >
          <div class="hidden absolute spec-offer" v-if="showOnModal!=='finish'">
            {{ $t('digitalPage.recommended') }}
          </div>

          <div class="spec-offer-padding brd-right td w-1/2 sm:w-2/4 lg:px-6" :class="{ 'sm:grow' : showOnModal}">
            {{ product.attribute.name }}
          </div>

          <div class="td w-1/2 sm:w-1/4">
            {{ product.region.pivot.price > 0 ? $filters.priceWithSpace(product.region.pivot.price) + '€' : $t('ui.free') }}
          </div>

          <div
              class="td grow w-full sm:w-1/2"
              v-if="showOnModal !== 'finish'"
          >
            <div v-if="showOnModal">
              <label class="cursor-pointer flex mb-6 fz14 border-top customRadio">
                <input
                    type="checkbox"
                    class="hidden"
                    :checked="selectedProductIds.includes(product.id)"
                    :value="product.id"
                    @change="updateSelectedProductId($event.target.value)"
                >
                <span class="checkСircle w-5 h-5 border mr-3 relative"></span>
              </label>
            </div>

            <div v-else>
              <button @click="showModal(product.description)" class="mb-2 sm:mb-0 btn blue-btn3 inline-flex mx-1.5">
                {{ $t('ourProduct.learnMore') }}
              </button>

              <button v-if="!showOnModal" @click="$vfm.show('example')" class="p-2 rounded btn blue-btn inline-flex mx-1.5">
                {{ $t('ui.getStarted') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalTablet",

  data() {
    return {
      sortBy: {
        column: 'price',
        ascending: 'ASC'
      },
      expandedGroups: {},
    }
  },

  props: {
    products: {
      type: Array,
      required: true
    },

    showOnModal: {
      type: Boolean,
      default: false
    },

    selectedProductIds: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    sortedProducts() {
      let products = this.products;

      return products.sort((a, b) => {
        return this.sortBy.ascending === 'ASC' ?
            this.sortBy.column === 'price' ? a.region.pivot.price - b.region.pivot.price :
                a.attribute[this.sortBy.column].localeCompare(b.attribute[this.sortBy.column]) :
            this.sortBy.column === 'price' ? b.region.pivot.price - a.region.pivot.price :
                b.attribute[this.sortBy.column].localeCompare(a.attribute[this.sortBy.column]);
      });
    },

    productGroupIds() {
      // Return unique product group ids
      return [
          ...new Set(
              this.products
                  .map(product => product.product_group_id)
                  .filter(productGroupId => productGroupId !== null)
          ),
          null, // Filtering nulls and adding to the end to make sure it is always at the end
      ];
    },

    productGroups() {
      let productGroups = {};

      this.products.forEach(product => {
        if (product.product_group !== null) {
          productGroups[product.product_group.id] = product.product_group;
        }
      })

      return productGroups;
    },
  },

  methods: {
    updateSort(column) {
      if (this.sortBy.column !== column) {
        this.sortBy.column = column;
      }

      this.sortBy.ascending = this.sortBy.ascending === 'ASC' ? 'DESC' : 'ASC';
    },

    showModal(description){
      this.$vfm.show('modalMore', {description})
    },

    updateSelectedProductId(productId) {
      productId = parseInt(productId);

      if (this.selectedProductIds.includes(productId)) {
        this.$emit('updateSelectedProductId', this.selectedProductIds.filter(id => id !== productId), 'digital_twin')
      } else {
        this.$emit('updateSelectedProductId', [...this.selectedProductIds, productId], 'digital_twin')
      }
    },

    filterProductsByProductGroup(products, productGroupId) {
      return this.products.filter(product => product.product_group_id === productGroupId);
    },

    isGroupExpanded(productGroupId) {
      if (productGroupId === null) {
        return true;
      }

      return this.expandedGroups[productGroupId] ?? false
    },

    toggleExpandGroup(productGroupId) {
      this.expandedGroups[productGroupId] = !(this.expandedGroups[productGroupId] ?? false);
    },
  }
}
</script>
