import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        products: [],
        oneTimeProducts: [],
    },

    getters: {
        getProducts: (state) => state.products,
        getOneTimeProducts: (state) => state.oneTimeProducts,
    },

    mutations: {
        setProducts: (state, products) => {
            state.products = products;
        },
        setOneTimeProducts: (state, oneTimeProducts) => {
            state.oneTimeProducts = oneTimeProducts;
        },
    },

    actions: {
        async loadProducts({commit}) {
            const response = await http.get(api.products.index);

            commit('setProducts', response.data.products);
        },
        async loadOneTimeProducts({commit}) {
            const response = await http.get(api.oneTimeProducts.index);

            commit('setOneTimeProducts', response.data.products);
        },
    }
}
