<template>
  <router-link class="w-full xs:w-auto max-w-mobile-btn xs:max-w-none uppercase px-8 py-4 rounded text-center text-green-500 bg-buttons-primary border border-2 border-buttons-primary font-medium hover:text-buttons-primary hover:bg-white inline-flex justify-center items-center space-x-4">
    <span><slot></slot></span>
    <img v-if="arrow"
         src="@/assets/images/partners/arrows.png"
         class="w-10"
    />
  </router-link>
</template>

<script>
export default {
  props: {
    arrow: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
