export default {
  install: (app) => {
    app.config.globalProperties.$trimWords = (text, length) => {
      if (typeof text !== 'string') {
        return text;
      }

      const parts = text.split(' ');

      let results = [];

      let total = 0;

      parts.forEach(part => {
        if ((total + part.length) <= length) {
          results.push(part);
          total += part.length + 1; // +1 for space
        }
      });

      const resultsString = results.join(' ');

      return resultsString + (resultsString !== text ? '...' : '');
    }

    app.config.globalProperties.$bookDemoUrl = () => {
      return '/contact-us';
    }

    app.config.globalProperties.$freeTrialUrl = () => {
      return '/contact-us';
    }

    app.config.globalProperties.$getCookiesDomain = () => {
      let cookieDomain = document.location.hostname;

      if (cookieDomain.startsWith('www.')) {
        cookieDomain = '.' + cookieDomain.substring(4);
      } else if (cookieDomain === 'localhost') {
        // Leave it as 'localhost'
      } else {
        cookieDomain = '.' + cookieDomain;
      }

      return cookieDomain;
    }
  }
}
