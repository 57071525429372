import { defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";

import lt from "@vee-validate/i18n/dist/locale/lt.json";
import en from "@vee-validate/i18n/dist/locale/en.json";

import { all as AllRules } from "@vee-validate/rules";

Object.keys(AllRules).forEach(rule => {
	defineRule(rule, AllRules[rule]);
});

configure({
	generateMessage: localize({
		en,
		lt,
	}),
});
