import http from "@/libs/axios"
import api from '@/api'

export default {
    namespaced: true,

    state: {
        savings: null,
    },

    getters: {
        savings: (state) => state.savings,
    },

    mutations: {
        setSavings: (state, savings) => {
            state.savings = savings;
        },
    },

    actions: {
        async loadSavings({commit}) {
            const response = await http.get(api.savings.index);

            commit('setSavings', response.data.savings);

            return response.data.savings;
        },
    }
}
