<template>
  <div v-if="calculators !== null" class="w-full">
    <CalculatorForm :calculators="calculators"
                    v-model:selectedCalculatorId="selectedCalculatorId"
    />
  </div>
</template>

<script>
import CalculatorForm from "@/components/Forms/Calculator/CalculatorForm";
import http from "@/libs/axios";
import api from "@/api";

export default {
  props: {
    preselectedCalculatorName: {
      type: String,
      default: () => null,
    }
  },

  components: {
    CalculatorForm,
  },

  data() {
    return {
      calculators: null,
      selectedCalculatorId: null,
    }
  },

  methods: {
    async loadCalculators() {
      const response = await http.get(api.calculators.index);

      this.calculators = response.data.calculators;

      if (this.calculators.length === 0) {
        this.$emit('no-calculators');
      }

      this.preselectCalculator();
    },

    preselectCalculator() {
      if (this.calculators === null || this.calculators.length === 0) {
        return;
      }

      if (this.selectedCalculatorId === null) {
        this.selectedCalculatorId = this.calculators[0].id;

        if (typeof this.preselectedCalculatorName !== 'undefined' && this.preselectedCalculatorName !== null) {
          const calculator = this.calculators.find(
            c => c.name.toLowerCase() === this.preselectedCalculatorName.toLowerCase()
          ) ?? null;

          if (calculator !== null) {
            this.selectedCalculatorId = calculator.id;
          } else {
            this.$emit('not-found-preselected-calculator-by-name')
          }
        }
      }
    },
  },

  mounted() {
    this.loadCalculators();
  },
}
</script>
