<template>
  <div style="opacity: 0; position: fixed; width: 1px; height: 1px; bottom: 1px; right: 1px; overflow: hidden;">
    <input type="email"
           name="email"
           v-model="email"
           placeholder="Your Email"
           ref="honeypot"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
    };
  },

  methods: {
    valid() {
      return this.email === null
          && (typeof this.$refs.honeypot.value === 'undefined' || this.$refs.honeypot.value.length === 0);
    },
  },
}
</script>
