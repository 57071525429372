<template>
  <h3 v-if="showTitle" class="font-medium text-center mb-4 text-2xl text-blue">{{ $t('iotPage.regions') }}</h3>
  <div class="flex justify-center switch-block flex-wrap mb-10" v-if="regions.length > 0">
    <select @change="regionChange" v-model="selectedRegion" class="standard w-auto">
      <option v-for="region in regions" :value="region" :key="region.id">{{ region.name }}</option>
    </select>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "IotSwitch",

  props: {
    showTitle: {
      type: Boolean,
      default: false
    },

    region: {
      type: Object,
      default: null
    }
  },

  watch: {
    region: function (newValue) {
      if (newValue !== this.selectedRegion && newValue) {
        this.selectedRegion = newValue;
      } else if (!newValue) {
        this.regionChange();
      }
    },

    regions: function (newValue, oldValue) {
      if (oldValue.length === 0) {
        this.setDefaultRegion();
      }
    }
  },

  emits: ['changeRegion'],

  data() {
    return {
      selectedRegion: null
    }
  },

  created() {
    this.setDefaultRegion();
  },

  computed: {
    ...mapState('Regions', ['regions'])
  },

  methods: {
    regionChange() {
      this.$emit('changeRegion', this.selectedRegion)
    },

    setDefaultRegion() {
      if (this.region) {
        this.selectedRegion = this.region;
      } else if (this.regions[0] && !this.selectedRegion) {
        this.selectedRegion = this.regions[0];
        this.regionChange()
      }
    }
  },
}
</script>