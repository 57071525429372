<template>
  <section v-if="showPlansServices" class="plansServices">
    <div class="container">
      <div class="flex justify-center">
        <div class="flex justify-center items-center flex-col plans-box">
          <h2>{{ $t('home.plansH') }}</h2>
          <p>{{ $t('home.plansText') }}</p>
          <BtnOnHome/>
        </div>
      </div>
    </div>
  </section>

  <section v-if="showContent('showSubscribe')" class="subscribe">
    <Form as="form" class="container" ref="newsletterForm" @submit="newsletterSubscribe">
      <honey-pot ref="honeypot" />

      <div class="flex mxm-15 flex-wrap items-center">
        <div class="w-full lg:w-1/2 px-15">
          <h3>{{ $t('home.subscribeH') }}</h3>
          <p>{{ $t('home.subscribeText') }}</p>
        </div>
        <div class="w-full flex justify-center lg:w-1/2 px-15">
          <Field class="w-full input-block relative" as="div" type="email" rules="required|email" name="email"
                 :label="$t('forms.email')" v-slot="{field}">
            <input type="email" v-bind="field" :placeholder="$t('forms.emailSubscribe')"/>
            <ErrorMessage as="div" name="email" class="errorMessage mt-4"/>
          </Field>

          <button class="btn inline-flex ml-auto">{{ $t('forms.subscribe') }}</button>
        </div>
      </div>
    </Form>
  </section>

  <section v-if="showContent('showLastNews')" class="lastNews">
    <div class="lastNews-box text-center">
      <h3>{{ $t('home.lastNewsH') }}</h3>
      <h5>{{ $t('home.lastNewsText') }}</h5>
      <p>{{ $t('home.lastNewsText2') }}</p>
      <button @click="$vfm.show('example')" class="blue-btn inline-flex btn"><span>{{
          $t('home.lastNewsButton')
        }}</span></button>
    </div>
  </section>

  <footer class="footer">
    <div class="container mb-5 footer-top">
      <div class="flex flex-row justify-between align-content mxm-15 flex-wrap">
        <div class="w-full  lg:w-1/4 px-15">
          <router-link :to="'/'"><img class="footer-logo mx-auto md:mx-0" src="../assets/images/logo.svg" alt="">
          </router-link>
          <p>{{ $t('footer.aboutText') }}</p>
        </div>
        <div class="w-full lg:w-2/3 px-15 pt-8">
          <div class="flex flex-row flex-wrap justify-between align-content mxm-15">
            <div class="w-full md:w-1/3 px-15">
              <h4>{{ $t('footer.services') }}</h4>
              <nav class="list-none">
                <li>
                  <router-link to="/iot-hub">{{ $t('home.menu.Iot') }}</router-link>
                </li>
                <li>
                  <router-link to="/database">{{ $t('home.menu.DataBase') }}</router-link>
                </li>
                <li>
                  <router-link to="/python">{{ $t('home.menu.Mathematical') }}</router-link>
                </li>
                <li>
                  <router-link to="/api">{{ $t('home.menu.Api') }}</router-link>
                </li>
                <li>
                  <router-link to="/digital-twin">{{ $t('home.menu.Digital') }}</router-link>
                </li>
              </nav>
            </div>
            <div class="w-full md:w-1/3 px-15">
              <h4>{{ $t('footer.legal') }}</h4>
              <nav class="list-none">
                <li>
                  <router-link :to="'/terms-conditions'">{{ $t('footer.terms') }}</router-link>
                </li>
                <li>
                  <router-link :to="'/privacy-policy'">{{ $t('footer.privacy') }}</router-link>
                </li>
              </nav>
            </div>
            <div class="w-full md:w-1/3 px-15">
              <h4>{{ $t('footer.contact') }}</h4>
              <address>
                <p>{{ $t('footer.address') }}</p>
                <p><a href="tel:+37060230887">+370 602 30887</a></p>
                <p><a href="mailto:info@easas.net">info@easas.net</a></p>
                <router-link to="/contact-us">
                  <button class="font-bold text-xs px-4 py-2 rounded border-blue border mr-1 mb-2" type="button">
                    {{ $t('footer.contactPage') }}
                  </button>
                </router-link>

                <div class="footer-social flex">
                  <a href="https://www.youtube.com/channel/UCZdgByWIaznD8lyWlOoXbzQ">
                    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M16.3229 4.45549C16.3229 2.56216 14.7284 0.967773 12.835 0.967773H3.8659C1.97243 0.967773 0.37793 2.56216 0.37793 4.45549V8.64075C0.37793 10.5341 1.97243 12.1285 3.8659 12.1285H12.835C14.7284 12.1285 16.3229 10.5341 16.3229 8.64075V4.45549ZM11.1408 6.84706L7.15456 8.84004C6.95525 8.93969 6.45697 8.84004 6.45697 8.64075V4.55514C6.45697 4.35584 6.95525 4.25619 7.15456 4.35584L11.0412 6.44847C11.1408 6.54812 11.2405 6.74741 11.1408 6.84706Z"
                          fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.linkedin.com/company/5116715/admin/">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.470794 4.75863H3.26117V13.8267H0.470794V4.75863ZM1.86598 0.274414C2.76289 0.274414 3.46048 0.971959 3.46048 1.8688C3.46048 2.76564 2.76289 3.46319 1.86598 3.46319C0.969077 3.46319 0.271484 2.76564 0.271484 1.8688C0.271484 0.971959 0.969077 0.274414 1.86598 0.274414Z"
                          fill="white"/>
                      <path
                          d="M5.05493 4.75891H7.74565V5.9547C8.14428 5.25715 9.04118 4.45996 10.4364 4.45996C13.3264 4.45996 13.8247 6.35329 13.8247 8.74488V13.7273H11.0343V9.34277C11.0343 8.24663 11.0343 6.95119 9.53947 6.95119C8.04462 6.95119 7.8453 8.04733 7.8453 9.24312V13.7273H5.05493V4.75891Z"
                          fill="white"/>
                    </svg>
                  </a>
                </div>
              </address>
              <div class="block md:hidden mt-5 mxm-15">
                <TheLanguage class="px-15 drop-top inline-block"></TheLanguage>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        © {{ new Date().getFullYear() }} {{ $t('footer.allRights') }}
      </div>
    </div>
    <CookiesAccept />
  </footer>
</template>

<script>
import BtnOnHome from "@/components/ui/BtnOnHome";
import TheLanguage from "@/components/ui/TheLanguage";
import {Form, Field, ErrorMessage} from "vee-validate";
import {mapActions} from "vuex";
import {useToast} from "vue-toastification";
import CookiesAccept from "@/components/CookiesAccept";

export default {
  name: "TheFooter",

  components: {CookiesAccept, BtnOnHome, TheLanguage, Form, Field, ErrorMessage},

  setup() {
    const toast = useToast();

    return {toast}
  },

  watch: {
    '$i18n.locale': function () {
      this.$refs.newsletterForm.validate()
    }
  },

  computed: {
    showPlansServices() {
      if ('showPlansServices' in this.$route.meta) {
        return this.$route.meta.showPlansServices;
      }

      return true;
    }
  },

  methods: {
    ...mapActions('Newsletter', ['sendNewsletter']),

    showContent(key) {
      if (key in this.$route.meta) {
        return this.$route.meta[key];
      }

      return true;
    },

    newsletterSubscribe(values) {
      if (!this.$refs.honeypot.valid()) {
        return;
      }

      this.$refs.newsletterForm.validate().then(validated => {
        if (validated.valid) {
          this.sendNewsletter({email: values.email}).then(() => {
            this.toast.success(this.$t('newsletter.subscribed'));
            this.$refs.newsletterForm.resetForm();
          }, (error) => {
            this.$refs.newsletterForm.setErrors(error.response.data.errors)
          })
        }
      })
    },
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/components/_theFooter.scss";

.cookie {
  &__floating {
    &__content {
      max-height: 300px;
    }

    &__buttons {
      &__button {
        &--accept, &--accept:hover {
          background-color: #293D7C;
          color: white;
        }
      }
    }
  }
}
</style>
