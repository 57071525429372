import {createRouter, createWebHistory} from "vue-router";
import {getFromLocalStorageWithExpiry} from "@/helpers/utils";
import store from "@/store";

import HomeView from "@/views/HomeView.vue";
const ContactUsView = () => import('@/views/ContactUsView');
const ChooseProductsView = () => import('@/views/ChooseProductsView');
const LoginView = () => import('@/views/guest/LoginView');
const RegistrationView = () => import('@/views/guest/RegistrationView');
const ProfileView = () => import('@/views/app/ProfileView');
const VerifyEmailView = () => import('@/views/app/VerifyEmailView');
const CheckoutView = () => import('@/views/CheckoutView');
const CheckoutOneTimeView = () => import('@/views/CheckoutOneTimeView');
const DigitalTwinView = () => import('@/views/DigitalTwinView');
const DatabaseView = () => import('@/views/DatabaseView');
const IotHubView = () => import('@/views/IotHubView');
const PythonView = () => import('@/views/PythonView');
const ApiView = () => import('@/views/ApiView');
const DashboardView = () => import('@/views/DashboardView');
const PrivacyPolicy = () => import('@/views/PrivacyPolicy');
const ManagementView = () => import('@/views/app/ManagementView');
const SupportView = () => import('@/views/app/SupportView');
const TicketView = () => import('@/views/app/TicketView');
const PasswordRecoveryView = () => import('@/views/guest/PasswordRecoveryView');
const PasswordResetView = () => import('@/views/guest/PasswordResetView');
const SuccessView = () => import('@/views/SuccessView');
const SuccessOneTimeOrderView = () => import('@/views/SuccessOneTimeOrderView');
const TermsConditionsView = () => import('@/views/TermsConditionsView');
const PlatformView = () => import('@/views/PlatformView');
const AboutUsView = () => import('@/views/AboutUsView');
const DemoView = () => import('@/views/DemoView');
const ResourcesView = () => import('@/views/ResourcesView');
const ArticleView = () => import('@/views/ArticleView');
const DiscoveryHubView = () => import('@/views/DiscoveryHubView');
const PartnersView = () => import('@/views/PartnersView');
const PricingView = () => import('@/views/PricingView');
const TrainingListView = () => import('@/views/TrainingListView');
const TrainingView = () => import('@/views/TrainingView');
const VideoView = () => import('@/views/VideoView');
const CaseStudyView = () => import('@/views/CaseStudyView.vue');
const UseCaseView = () => import('@/views/UseCaseView.vue');

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/contact-us",
            name: "contactUs",
            component: ContactUsView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false
            }
        },
        {
            path: "/products",
            name: "chooseProducts",
            component: ChooseProductsView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/login",
            name: "loginView",
            component: LoginView,
            meta: {
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: "/password-recovery",
            name: "PasswordRecovery",
            component: PasswordRecoveryView,
            meta: {
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: "/password-reset",
            name: "PasswordReset",
            component: PasswordResetView,
            meta: {
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: "/register",
            name: "registrationView",
            component: RegistrationView,
            meta: {
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: "/profile",
            name: "ProfileView",
            component: ProfileView,
            meta: {
                showPlansServices: false,
                showSubscribe: false,
                ignoreEmailVerification: true,
            }
        },
        {
            path: "/verify-email",
            name: "VerifyEmailView",
            component: VerifyEmailView,
            meta: {
                showPlansServices: false,
                showSubscribe: false,
                ignoreEmailVerification: true,
            }
        },
        {
            path: "/checkout",
            name: "Checkout",
            component: CheckoutView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false
            }
        },
        {
            path: "/checkout/non-recurring",
            name: "CheckoutOneTime",
            component: CheckoutOneTimeView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false
            }
        },
        {
            path: "/management",
            name: "Management",
            component: ManagementView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false
            }
        },
        {
            path: "/support",
            name: "Support",
            component: SupportView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false
            }
        },
        {
            path: "/support/page/:page",
            name: "SupportPage",
            component: SupportView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/support/:ticketId",
            name: "TicketView",
            component: TicketView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/digital-twin",
            name: "DigitalTwin",
            component: DigitalTwinView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/database",
            name: "DatabaseView",
            component: DatabaseView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/iot-hub",
            name: "IotHubView",
            component: IotHubView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/python",
            name: "PythonView",
            component: PythonView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/api",
            name: "ApiView",
            component: ApiView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/dashboard",
            name: "DashboardView",
            component: DashboardView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false
            }
        },
        {
            path: "/resources/:activeTab?",
            name: "ResourcesView",
            component: ResourcesView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/articles/:articleId",
            name: "ArticleView",
            component: ArticleView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/discovery-hub",
            name: "DiscoveryHubView",
            component: DiscoveryHubView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/payment-success",
            name: "SuccessView",
            component: SuccessView,
            meta: {
                resource: 'AvailableToEveryone',
                showSubscribe: false,
                showPlansServices: false,
                showLastNews: false,
            }
        },
        {
            path: "/payment-success/non-recurring",
            name: "SuccessOneTimeOrderView",
            component: SuccessOneTimeOrderView,
            meta: {
                resource: 'AvailableToEveryone',
                showSubscribe: false,
                showPlansServices: false,
                showLastNews: false,
            }
        },
        {
            path: "/privacy-policy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
            meta: {
                resource: 'AvailableToEveryone',
                showSubscribe: false,
                showPlansServices: false,
                showLastNews: false,
            }
        },
        {
            path: "/terms-conditions",
            name: "TermsConditions",
            component: TermsConditionsView,
            meta: {
                resource: 'AvailableToEveryone',
                showSubscribe: false,
                showPlansServices: false,
                showLastNews: false,
            }
        },
        {
            path: "/platform",
            name: "platform",
            component: PlatformView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/about",
            name: "about",
            component: AboutUsView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/demo",
            name: "demo",
            component: DemoView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/partners",
            name: "partners",
            component: PartnersView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/pricing",
            name: "pricing",
            component: PricingView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/training",
            name: "training",
            component: TrainingListView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/training/:trainingId",
            name: "TrainingView",
            component: TrainingView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/videos/:videoId",
            name: "VideoView",
            component: VideoView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/case-studies/:caseStudyId",
            name: "CaseStudyView",
            component: CaseStudyView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
        {
            path: "/use-cases/:useCaseId",
            name: "UseCaseView",
            component: UseCaseView,
            meta: {
                resource: 'AvailableToEveryone',
                showPlansServices: false,
                showSubscribe: false,
                showLastNews: false,
            }
        },
    ],
    scrollBehavior() {
        return { top: 0 }
    },
});

router.beforeEach((to, _, next) => {
    const accessToken = getFromLocalStorageWithExpiry('accessToken');

    const ignoreEmailVerification = to?.meta?.ignoreEmailVerification ?? false;
    const user = store.getters['Auth/getAuthUser'];

    // Redirect user to email verification page if necessary
    if (!ignoreEmailVerification && typeof user !== 'undefined' && user !== null && !user.email_verified) {
        return next({name: 'VerifyEmailView'});
    }

    if (to.meta.resource !== 'AvailableToEveryone') {
        if ((!accessToken && to.meta.resource !== 'Auth') || (!user && accessToken)) {
            store.dispatch('Auth/logout');

            return next({name: 'loginView'});
        } else if (to.meta.redirectIfLoggedIn && accessToken) {
            return next({name: 'ProfileView'});
        }
    }

    return next();
})

export default router;
