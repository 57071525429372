export default {
  install: (app) => {
    app.config.globalProperties.$minutesForHumans = (minutes) => {
      const hours = parseInt(minutes / 60);
      const mins = minutes - (hours * 60);

      let parts = [];

      parts.push(`${hours} h`)
      parts.push(`${mins} min`)

      return parts.join(' ');
    }
  }
}
