import http from "@/libs/axios";
import api from "@/api";

export default {
    namespaced: true,

    actions: {
        async getCharts(_, params) {
            const response = await http.get(api.management.index, {params});

            return response.data.charts;
        }
    }
}