import { createApp  } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

//3rd party plugins
import i18n from '@/libs/i18n/index'
import VueSmoothScroll from 'vue3-smooth-scroll'
import '@/libs/vee-validate';
import Toast from "vue-toastification";
import toastOptions from '@/libs/toastification';
import { vfmPlugin } from "vue-final-modal";
import LoadScript from 'vue-plugin-load-script';

// import main styles
import "@/assets/scss/main.scss";
import "vue-toastification/dist/index.css";
import 'v-calendar/dist/style.css';
import cookies from "@/plugins/cookies";
import HoneyPot from "@/components/Forms/HoneyPot.vue";
import UILink from "@/components/ui/UILink.vue";
import UIButton from "@/components/ui/UIButton.vue";
import parseMinutes from "@/plugins/parseMinutes";
import productHelpers from "@/plugins/productHelpers";
import utils from "@/plugins/utils";

const app = createApp(App)

app.config.globalProperties.$filters = {
    priceWithSpace(value) {
        return value.toFixed(2).toLocaleString()
    },

    sizeWithUnit(type, value) {
        let unit = 'MB';

        switch (type) {
            case 'storage':
                unit = 'GB';
                break;
            case 'backup':
                unit = 'TB';
                break;
        }

        return value + unit;
    }
}

app.component('HoneyPot', HoneyPot);
app.component('UiLink', UILink);
app.component('UiButton', UIButton);

app.use(router)
    .use(store)
    .use(i18n)
    .use(Toast, toastOptions)
    .use(VueSmoothScroll, {
        duration: 700,
        updateHistory: false
    })
    .use(vfmPlugin({
        key: '$vfm',
        componentName: 'VueFinalModal',
        dynamicContainerName: 'ModalsContainer'
    }))
    .use(LoadScript)
    .use(cookies, {
        store: store,
    })
    .use(parseMinutes)
    .use(utils, {
        store: store,
    })
    .use(productHelpers, {
        store: store,
    })
    .mount('#app')

if (typeof process.env.VUE_APP_TIDIO_API_KEY !== 'undefined'
  && process.env.VUE_APP_TIDIO_API_KEY !== null
  && process.env.VUE_APP_TIDIO_API_KEY.length > 0
) {
    require('./services/live-chat');
}
