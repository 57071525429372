export default {
    auth: {
        login: '/login',
        register: '/register',
        logout: '/logout',
        passwordRecovery: '/password-recovery',
        passwordReset: '/password-reset',
        resendEmailVerification: '/resend-verification-email',
    },

    accountSetting: {
        show: '/account/me',
        update: '/account/me',
        nextStepPopupShown: '/account/me/next-step-popup-shown',
    },

    regions: {
        index: '/regions',
    },

    products: {
        index: '/products',
    },

    oneTimeProducts: {
        index: '/products/one-time',
    },

    training: {
        show: '/training/{productId}',
        register: '/training/{productId}/register',
    },

    coupons: {
        show: '/coupons'
    },

    orders: {
        index: '/orders',
        store: '/orders',
        cancel: '/orders/{orderId}/cancel',
        reactivate: '/orders/{orderId}/reactivate',
        addToCart: '/orders/add-to-cart',
    },

    paypal: {
        fetchOrder: '/paypal/fetch-order/{token}',
    },

    oneTimeOrders: {
        store: '/orders/one-time',
    },

    management: {
        index: '/management',
    },

    support: {
        index: '/support',
        show: '/support/{ticketId}',
        addTicket: '/support/add-ticket',
        reply: '/support/reply',
        countUnread: '/support/count-reply-unread',
    },

    contactUs: "/contact-us",

    getInTouch: "/get-in-touch",

    newsletter: '/newsletter',

    tags: {
        index: '/tags',
    },

    articles: {
        index: '/articles',
        show: '/articles/{articleId}',
        latest: '/articles/latest/{category}',
    },

    videos: {
        index: '/videos',
        show: '/videos/{videoId}',
    },

    caseStudies: {
        index: '/case-studies',
        show: '/case-studies/{caseStudyId}',
    },

    questions: {
        index: '/questions',
    },

    liveChat: "/live-chat",

    calculators: {
        index: '/calculators',
        submit: '/calculators/{calculatorId}',
        sendEmail: '/calculators/{calculatorId}/send-email'
    },

    savings: {
        index: '/savings',
    },

    invoices: {
        index: '/invoices',
        download: '/invoices/{invoiceId}/download',
    },

    authorizationFee: "/authorization-fee",

    consultations: {
        balances: '/consultations/balance',
        index: '/consultations',
    },

    testimonials: {
        index: '/testimonials',
    },

    useCases: {
        index: '/use-cases',
    },
}
