import {getFromLocalStorageWithExpiry, setToLocalStorageWithExpiry} from "@/helpers/utils";

export default {
    namespaced: true,

    state: {
        selectedProducts: getFromLocalStorageWithExpiry('selectedProducts'),
        selectedOneTimeProducts: getFromLocalStorageWithExpiry('selectedOneTimeProducts') ?? {},
    },

    getters: {
        getSelectedProducts: (state) => state.selectedProducts,
        getSelectedOneTimeProducts: (state) => state.selectedOneTimeProducts,
    },

    mutations: {
        resetSelectedProducts: (state) => {
            localStorage.removeItem('selectedProducts');
            state.selectedProducts = null;
        },

        setSelectedProducts: (state, selectedProducts) => {
            setToLocalStorageWithExpiry('selectedProducts', selectedProducts);
            state.selectedProducts = selectedProducts;
        },

        updateSelectedProducts: (state, {key, selectedProducts}) => {
            state.selectedProducts[key] = selectedProducts;

            setToLocalStorageWithExpiry('selectedProducts', state.selectedProducts);
        },

        resetSelectedOneTimeProducts: (state) => {
            localStorage.removeItem('selectedOneTimeProducts');
            state.selectedOneTimeProducts = {};
        },

        setSelectedOneTimeProducts: (state, selectedOneTimeProducts) => {
            setToLocalStorageWithExpiry('selectedOneTimeProducts', selectedOneTimeProducts);
            state.selectedOneTimeProducts = selectedOneTimeProducts;
        },

        updateSelectedOneTimeProducts: (state, selectedOneTimeProduct) => {
            if (typeof state.selectedOneTimeProducts === 'undefined' || state.selectedOneTimeProducts === null) {
                state.selectedOneTimeProducts = {};
            }

            state.selectedOneTimeProducts[selectedOneTimeProduct.productId] = selectedOneTimeProduct;

            setToLocalStorageWithExpiry('selectedOneTimeProducts', state.selectedOneTimeProducts);
        },

        removeSelectedOneTimeProduct: (state, selectedOneTimeProductId) => {
            if (typeof state.selectedOneTimeProducts === 'undefined' || state.selectedOneTimeProducts === null) {
                state.selectedOneTimeProducts = {};
            }

            delete state.selectedOneTimeProducts[selectedOneTimeProductId];

            setToLocalStorageWithExpiry('selectedOneTimeProducts', state.selectedOneTimeProducts);
        },
    },
}
