import Cookies from 'js-cookie';

function getAcceptedCookiesFromStorage() {
    const acceptedCookiesStr = Cookies.get('accepted-cookies');

    return (typeof acceptedCookiesStr !== 'undefined' && acceptedCookiesStr !== null)
      ? JSON.parse(acceptedCookiesStr)
      : null;
}

function setAcceptedCookiesInStorage(cookies) {
    let cookieDomain = document.location.hostname;

    if (cookieDomain.startsWith('www.')) {
        cookieDomain = '.' + cookieDomain.substring(4);
    } else if (cookieDomain === 'localhost') {
        // Leave it as 'localhost'
    } else {
        cookieDomain = '.' + cookieDomain;
    }

    Cookies.set('accepted-cookies', JSON.stringify(cookies), {expires: 365, domain: cookieDomain});
}

export default {
    namespaced: true,

    state: {
        acceptedCookies: getAcceptedCookiesFromStorage(),
        loadedAnalyticsScripts: false,
    },

    getters: {
        hasSelectedCookies: (state) => state.acceptedCookies !== null,
        acceptedPerformanceCookies: (state) => state.acceptedCookies !== null && state.acceptedCookies.performance,
        acceptedAnalyticsCookies: (state) => state.acceptedCookies !== null && state.acceptedCookies.analytics,
        acceptedCookies: (state) => state.acceptedCookies ? state.acceptedCookies : {
            analytics: false,
            performance: false,
        },
        loadedAnalyticsScripts: (state) => state.loadedAnalyticsScripts,
    },

    mutations: {
        setAcceptedCookies: (state, cookies) => {
            state.acceptedCookies = cookies;
        },
        setLoadedAnalyticsScripts: (state) => {
            state.loadedAnalyticsScripts = true;
        },
    },

    actions: {
        setAcceptedCookies({commit}, cookies) {
            commit('setAcceptedCookies', cookies);

            setAcceptedCookiesInStorage(cookies);
        },
        setLoadedAnalyticsScripts({commit}) {
            commit('setLoadedAnalyticsScripts');
        },
    }
}
