<template>
  <section class="homeDemo">
    <div class="container my-12">
      <div class="flex mxm-15 flex-wrap mb-16">
        <div class="w-full lg:w-2/5 md:w-1/2 px-15 text-blue">
          <h2 class="my-6">{{ $t('home.demoH') }}</h2>

          <div class="mb-6 flex justify-center sm:block">
            <ui-link :to="$freeTrialUrl()" :arrow="true">
              {{ $t('home.menu.GetDemo') }}
            </ui-link>
          </div>
        </div>
        <div class="w-full lg:w-3/5 md:w-1/2 px-15">
          <div class="banner-demo">
            <img src="@/assets/images/Get_demo2.jpg" alt="demo" loading="lazy">
          </div>
        </div>

      </div>
    </div>

    <div class="container my-12">
      <div class="flex mxm-15 flex-wrap mb-16">
        <div class="w-full lg:w-1/4 md:w-1/3 px-15 my-2 text-blue">
          <h2>{{ $t('home.timeH') }}</h2>
          <p>{{ $t('home.timeP') }}</p>
        </div>
        <div class="w-full lg:w-3/4 md:w-2/3 px-15 text-blue">
          <img class="my-6 hidden lg:block" src="@/assets/images/line.jpg" alt="line" loading="lazy">
          <img class="my-6 lg:hidden" src="@/assets/images/line-mobile.jpg" alt="line" loading="lazy">
        </div>
      </div>
    </div>
  </section>
  <section class="bg-gray">
    <div class="container my-12 ">
      <div class="flex mxm-15 flex-wrap mb-16">
        <div class="w-full md:w-1/3 px-15 my-2 text-blue  ">
          <div class="border-b-2 h-full">
            <h3 class="text-3xl my-3 font-semibold">1 345 000 <small>eur</small></h3>
            <p>{{ $t('home.saveP') }}</p>
          </div>
        </div>
        <div class="w-full md:w-1/3 px-15 my-2 text-blue">
          <div class="border-b-2 h-full">
            <h3 class="text-3xl my-3 font-semibold">8 <small>{{ $t('home.hoursS') }}</small></h3>
            <p>{{ $t('home.hoursP') }}</p>
          </div>
        </div>
        <div class="w-full md:w-1/3 px-15 my-2 text-blue">
          <div class="border-b-2 h-full">
            <h3 class="text-3xl my-3 font-semibold">15%</h3>
            <p>{{ $t('home.co2P') }}</p>
          </div>
        </div>
      </div>
    </div>

    <ClientsSection />

    <div class="container my-12">
      <div class="bg-white py-2 px-4 sm:p-4 md:px-6">
        <div class="flex items-center mxm-15 flex-wrap my-4">
          <div class="w-full lg:w-2/5 md:w-1/2 px-15 text-blue">
            <h3 class="my-6 text-3xl text-blue font-semibold">{{ $t('home.workingH') }}</h3>
            <p class="text-xl mb-4">{{ $t('home.workingP') }}</p>
            <div class="md:mt-10 mb-6 flex justify-center sm:block">
              <ui-link :to="$freeTrialUrl()" :arrow="true">
                {{ $t('home.menu.GetDemo') }}
              </ui-link>
            </div>
          </div>
          <div class="w-full lg:w-3/5 md:w-1/2 px-15 hidden md:block">
            <div class="banner-demo">
              <img src="@/assets/images/Main_Industrial_Clients.jpg" loading="lazy">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showEstimate" class="container my-12">
      <h2 class="text-blue mt-16 md:my-8">{{ $t('home.estimate') }}</h2>
      <div class="bg-white py-8 px-4 md:pt-2 md:px-4 sm:p-4 md:p-10 md:px-6 mt-6 md:mt-14 md:mt-0">
        <div class="xs:hidden text-center mb-4 text-gray-500">
          {{ $t('calculatorForm.selectUseCase') }}
        </div>
        <CalculatorElement @no-calculators="showEstimate = false" />
      </div>
    </div>

    <div class="container my-12">
      <h4 class=" text-blue lg:w-1/3 my-8 font-semibold">{{ $t('home.fit') }}</h4>
      <div class="flex flex-wrap my-4 justify-center mxm-15">
        <div class="w-full lg:w-1/3 px-15 text-blue">
          <h2 class="mb-4">{{ $t('home.hubH') }}</h2>
          <p class="mb-6">{{ $t('home.hubP') }}</p>
          <div class="mb-6 flex justify-center sm:block">
            <ui-link to="/resources" :arrow="true">
              {{ $t('home.hubB') }}
            </ui-link>
          </div>
        </div>
        <div class="grid md:grid-cols-2 w-full gap-y-4 lg:w-2/3">
          <ArticleCard v-for="article in articles" :key="article.id"
                       :article="article"
                       class="mx-15"
          />
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import CalculatorElement from "@/components/Forms/Calculator/CalculatorElement";
import http from "@/libs/axios";
import api from "@/api";
import ArticleCard from "@/components/ArticleCard.vue";
import ClientsSection from "@/components/ClientsSection.vue";
import UiLink from "@/components/ui/UILink.vue";

export default {
  name: "HomeDemo",
  components: {
    UiLink,
    ClientsSection,
    ArticleCard,
    CalculatorElement,
  },
  data() {
    return {
      showEstimate: true,
      articles: null,
      url: process.env.VUE_APP_ADMIN_URL,
    }
  },
  async mounted() {
    this.articles = (await http.get(
        api.articles.latest.replaceAll('{category}', 'discovery_hub'),
        {
          params: {
            limit: 2,
          }
        }
    )).data.articles;
  },
}
</script>

<style scoped>

</style>
