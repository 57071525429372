export default {
  install: (app, options) => {
    const store = options.store;

    app.config.globalProperties.$getProductPrice = (product) => {
      const regionId = store.getters['Regions/getRegionId'];

      if (typeof regionId === 'undefined' || regionId === null) {
        return null;
      }

      const productRegion = product.regions.find((reg) => reg.id === regionId);

      return productRegion.pivot.price.toFixed(2).toLocaleString();
    }

    app.config.globalProperties.$isProductFree = (product) => {
      const regionId = store.getters['Regions/getRegionId'];

      if (typeof regionId === 'undefined' || regionId === null) {
        return null;
      }

      const productRegion = product.regions.find((reg) => reg.id === regionId);

      return productRegion.pivot.price === 0 || productRegion.pivot.price === null;
    }
  }
}
