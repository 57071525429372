<template>
  <section class="home-product">
    <div class="container">
      <div class="flex mxm-15 flex-wrap justify-between mb-12">
        <div class="w-full sm:w-1/2 lg:w-5/12 flex flex-col justify-between px-15 mb-8">
          <div>
            <h4 class="mb-4 font-semibold text-xl text-blue">{{$t('home.homeView.serviceH')}}</h4>
            <p class="text-justify xs:text-left">{{$t('home.homeView.serviceP')}}</p>
          </div>
          <div class="my-4 flex justify-center sm:block">
            <router-link to="/partners"
                         class="w-full xs:w-auto max-w-mobile-btn xs:max-w-none text-center uppercase px-8 py-4 rounded text-primary-900 bg-transparent border border-buttons-primary font-medium hover:text-buttons-primary hover:bg-white inline-flex justify-center items-center space-x-4"
            >
              {{ $t('home.homeView.partnersLearnMore') }}
            </router-link>
          </div>
        </div>
        <div class="w-full sm:w-1/2 lg:w-5/12 flex flex-col justify-between px-15 sm:mb-8">
          <div>
            <h4 class="mb-4 font-semibold text-xl text-blue">{{$t('home.homeView.industrialH')}}</h4>
            <p class="text-justify xs:text-left">{{$t('home.homeView.industrialP')}}</p>
          </div>
          <div class="my-4 flex justify-center sm:block">
            <ui-link to="/platform">
              {{$t('home.homeView.clientsLearnMore')}}
            </ui-link>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center md:items-stretch md:flex-row space-y-8 md:space-y-0 md:space-x-8 mt-8 mb-16 md:my-28 justify-center">
        <div class="w-full md:w-52 bg-primary-800 px-8 md:px-4 pt-8 pb-8 xs:pt-2 xs:pb-8">
          <div class="text-white font-semibold text-2xl">
            01
          </div>

          <div class="text-green-500 uppercase text-base font-semibold mt-4 pr-2 md:h-[72px] max-w-[200px] xs:max-w-none">
            {{ $t('home.homeView.realH') }}
          </div>

          <div class="text-white text-sm mt-4">
            {{ $t('home.homeView.realP') }}
          </div>
        </div>

        <div class="w-full md:w-52 bg-primary-800 px-8 md:px-4 pt-8 pb-8 xs:pt-2 xs:pb-8">
          <div class="text-white font-semibold text-2xl">
            02
          </div>

          <div class="text-green-500 uppercase text-base font-semibold mt-4 pr-2 md:h-[72px]"
               v-html="$t('home.homeView.shorterH')"
          ></div>

          <div class="text-white text-sm mt-4">
            {{ $t('home.homeView.shorterP') }}
          </div>
        </div>

        <div class="w-full md:w-52 bg-primary-800 px-8 md:px-4 pt-8 pb-8 xs:pt-2 xs:pb-8">
          <div class="text-white font-semibold text-2xl">
            03
          </div>

          <div class="text-green-500 uppercase text-base font-semibold mt-4 pr-2 md:h-[72px]">
            {{ $t('home.homeView.userH') }}
          </div>

          <div class="text-white text-sm mt-4">
            {{ $t('home.homeView.userP') }}
          </div>
        </div>
      </div>

      <div class="flex flex-wrap mxm-15 justify-between xs:mb-8">
        <div class="w-full sm:w-1/2 flex flex-col justify-between px-15 mb-8">
          <div class="bg-white">
            <div>
              <h4 class="mb-4 font-semibold text-xl text-blue">{{$t('home.homeView.industriesH')}}</h4>
              <p>{{$t('home.homeView.industriesP')}}</p>
            </div>
            <div class="flex flex-wrap mxm-7 mt-4 lg:pr-12">
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.biomassPowerPlan')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.drying')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.building')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.ventilation')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.districtHeating')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.glassMeltingFurnace')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.refrigeration')}}</button>
            </div>
          </div>
        </div>
        <div class="w-full sm:w-1/2 flex flex-col justify-between px-15 mb-8">
          <div class="bg-white">
            <div>
              <h4 class="mb-4 font-semibold text-xl text-blue">{{$t('home.homeView.rolesH')}}</h4>
              <p>{{$t('home.homeView.rolesP')}}</p>
            </div>
            <div class="flex flex-wrap mt-4 lg:mt-8">
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.engineers')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.dataAnalysts')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.technicians')}}</button>
              <button class="mx-7p py-1 px-3 border-blue mb-2 rounded border uppercase" disabled>{{$t('home.tag.managers')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeProduct"
}
</script>

<style scoped>

</style>
