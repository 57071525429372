<template>
  <div v-if="testimonials !== null && testimonials.length > 0" class="container my-12">
    <h3 class="text-3xl text-blue font-semibold text-center my-4 ">{{ $t('home.clientsH') }}</h3>

    <div class="flex space-x-4 justify-center items-center mt-12">
      <button class="transform rotate-180"
              @click="previousTestimonial()"
              :class="{'opacity-10': isFirstSelectedTestimonial}"
              :disabled="isFirstSelectedTestimonial"
      >
        <svg class="w-12 h-12 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
        </svg>
      </button>
      <div class="flex flex-grow space-x-4 justify-center xs:justify-between">
        <button v-for="testimonial in visibleTestimonials" :key="testimonial.id"
                class="w-36 h-36 flex justify-center items-center bg-white rounded-xl p-1 hover:opacity-70 border-2"
                :class="selectedTestimonialId === testimonial.id ? 'border-gray-300' : 'border-transparent'"
                @click="selectedTestimonialId = testimonial.id"
        >
          <img v-if="testimonial.logo_image_url"
               class="object-contain w-full h-full"
               :src="testimonial.logo_image_url"
               :alt="testimonial.headline"
               loading="lazy"
          >
        </button>
      </div>
      <button @click="nextTestimonial()"
              :class="{'opacity-10': isLastSelectedTestimonial}"
              :disabled="isLastSelectedTestimonial"
      >
        <svg class="w-12 h-12 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
        </svg>
      </button>
    </div>

    <div class="mb-16 mt-4 flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
      <template v-if="selectedTestimonial">
        <div class="w-full md:w-1/2 lg:w-2/5 bg-white p-6 rounded-md">
          <h3 class="my-6 text-2xl text-blue font-semibold">
            {{ selectedTestimonial.headline }}
          </h3>

          <div v-if="selectedTestimonial.description"
               class="mt-6 prose max-w-none break-words ck-content"
               :class="{'line-clamp-[10]': !testimonialContentExpanded}"
               style="overflow: hidden;"
               v-html="selectedTestimonial.description"
               id="testimonial-content"
          ></div>

          <div v-if="canTestimonialContentExpand && !testimonialContentExpanded"
               class="mt-2"
          >
            <button class="text-primary-700"
                    @click="testimonialContentExpanded = true"
            >
              {{ $t('readMore') }}
            </button>
          </div>

          <p class="font-semibold mt-6">
            {{ selectedTestimonial.author }}
          </p>
          <p class="text-sm mt-1 text-gray-600">
            {{ selectedTestimonial.position }}
          </p>
        </div>

        <div v-if="selectedTestimonial.has_article"
             class="w-full md:w-1/2 lg:w-2/5 bg-white p-6 rounded-md"
        >
          <div class="flex flex-wrap my-6 items-start">
            <h3 class="text-2xl text-blue font-semibold flex-grow">
              {{ selectedTestimonial.article_headline }}
            </h3>

            <img v-if="selectedTestimonial.article_image_url" v-cloak
                 class="max-w-32 max-h-24"
                 :src="selectedTestimonial.article_image_url"
            />
          </div>

          <div v-if="selectedTestimonial.article_content"
               class="mt-6 prose max-w-none break-words ck-content"
               :class="{'line-clamp-[10]': !articleContentExpanded}"
               style="overflow: hidden;"
               v-html="selectedTestimonial.article_content"
               id="article-content"
          ></div>

          <div v-if="canArticleContentExpand && !articleContentExpanded"
               class="mt-2"
          >
            <button class="text-primary-700"
                    @click="articleContentExpanded = true"
            >
              {{ $t('readMore') }}
            </button>
          </div>

          <div v-if="selectedTestimonial.article_url"
               class="flex justify-end mt-6"
          >
            <a :href="selectedTestimonial.article_url"
               class="px-3 py-1 text-white bg-buttons-primary hover:bg-buttons-primary-hover rounded"
            >
              {{ $t('caseStudy') }}
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import http from "@/libs/axios";
import api from "@/api";

export default {
  data() {
    return {
      testimonials: null,
      selectedTestimonialId: null,
      visibleCount: 5,
      visibleStart: 0,
      articleContentExpanded: false,
      testimonialContentExpanded: false,
      canArticleContentExpand: false,
      canTestimonialContentExpand: false,
    }
  },

  watch: {
    selectedTestimonialId(value) {
      const selectedIndex = this.testimonials
          .map(testimonial => testimonial.id)
          .indexOf(value);

      if (selectedIndex < this.visibleStart) {
        this.visibleStart = selectedIndex;
      } else if ((this.visibleStart + this.visibleCount) < selectedIndex + 1) {
        let newStart = selectedIndex + 1 - this.visibleCount;

        if (newStart < 0) {
          newStart = 0;
        }

        this.visibleStart = newStart;
      }

      this.testimonialContentExpanded = false;
      this.articleContentExpanded = false;

      this.$nextTick(() => {
        const testimonialContentEl = document.getElementById('testimonial-content');
        if (typeof testimonialContentEl !== 'undefined' && testimonialContentEl !== null) {
          this.canTestimonialContentExpand = testimonialContentEl.scrollHeight > testimonialContentEl.clientHeight;
        }

        const articleContentEl = document.getElementById('article-content');
        if (typeof articleContentEl !== 'undefined' && articleContentEl !== null) {
          this.canArticleContentExpand = articleContentEl.scrollHeight > articleContentEl.clientHeight;
        }
      })
    },
  },

  computed: {
    selectedTestimonial() {
      if (this.selectedTestimonialId === null) {
        return null;
      }

      return this.testimonials.find((testimonial) => testimonial.id === this.selectedTestimonialId);
    },

    visibleTestimonials() {
      if (this.selectedTestimonialId === null || this.testimonials === null || this.testimonials.length === 0) {
        return [];
      }

      let testimonials = JSON.parse(JSON.stringify(this.testimonials));

      return testimonials.splice(this.visibleStart, this.visibleCount);
    },

    isFirstSelectedTestimonial() {
      if (this.selectedTestimonialId === null || this.testimonials === null || this.testimonials.length === 0) {
        return false;
      }

      const firstTestimonial = this.testimonials[0];

      return firstTestimonial.id === this.selectedTestimonialId;
    },

    isLastSelectedTestimonial() {
      if (this.selectedTestimonialId === null || this.testimonials === null || this.testimonials.length === 0) {
        return false;
      }

      let testimonials = JSON.parse(JSON.stringify(this.testimonials));

      const lastTestimonial = testimonials.splice(this.testimonials.length - 1, 1)[0];

      return lastTestimonial.id === this.selectedTestimonialId;
    },
  },

  methods: {
    async loadData() {
      const response = await http.get(api.testimonials.index);

      this.testimonials = response.data.data;

      const defaultTestimonial = this.testimonials.find((testimonial) => {
        return testimonial.is_default;
      });

      if (defaultTestimonial) {
        this.selectedTestimonialId = defaultTestimonial.id;
      } else if (this.testimonials.length > 0) {
        this.selectedTestimonialId = this.testimonials[0].id;
      }
    },

    previousTestimonial() {
      if (!this.isFirstSelectedTestimonial) {
        const selectedIndex = this.testimonials
            .map(testimonial => testimonial.id)
            .indexOf(this.selectedTestimonialId);

        this.selectedTestimonialId = this.testimonials[selectedIndex-1].id;
      }
    },

    nextTestimonial() {
      if (!this.isLastSelectedTestimonial) {
        const selectedIndex = this.testimonials
            .map(testimonial => testimonial.id)
            .indexOf(this.selectedTestimonialId);

        this.selectedTestimonialId = this.testimonials[selectedIndex+1].id;
      }
    },

    resetVisibleCount() {
      if (window.matchMedia('screen and (max-width: 440px)').matches) {
        this.visibleCount = 1;
      } else if (window.matchMedia('screen and (max-width: 600px)').matches) {
        this.visibleCount = 2;
      } else if (window.matchMedia('screen and (max-width: 800px)').matches) {
        this.visibleCount = 3;
      } else if (window.matchMedia('screen and (max-width: 950px)').matches) {
        this.visibleCount = 4;
      } else {
        this.visibleCount = 5;
      }
    },
  },

  mounted() {
    this.loadData();

    this.resetVisibleCount();

    window.addEventListener("resize", this.resetVisibleCount);
  },

  unmounted() {
    window.removeEventListener("resize", this.resetVisibleCount);
  },
}
</script>
