<template>
  <div class="bg-primary-800 headline-bg-image flex flex-col justify-center py-16 sm:py-[14vh] lg:py-0 lg:h-blue-headline">
    <div class="container">
      <div class="flex flex-wrap mxm-15">
        <div class="w-full lg:w-1/2 sm:w-3/4 flex flex-col justify-center">
          <div class="px-15 text-center xs:text-left">
            <div class="flex flex-col xs:flex-row items-center xs:space-x-8">
              <div>
                <div class="text-white text-4xl font-medium leading-none">
                  {{ $t('home.homeView.h11') }}
                </div>

                <h1 class="text-white text-6xl sm:text-8xl leading-none uppercase">
                  {{ $t('home.homeView.h12') }}
                </h1>
              </div>

              <div class="mt-6 xs:mt-0 text-white text-3xl font-light leading-normal">
                <div>{{ $t('home.homeView.p1') }}</div>
                <div>{{ $t('home.homeView.p2') }}</div>
                <div class="text-green-500">{{ $t('home.homeView.p3') }}</div>
              </div>
            </div>

            <div class="relative mt-4 bg-green-500 h-[2px] w-full sm:max-w-[500px] md:max-w-[510px]"></div>

            <div class="text-white text-xl font-light mt-6">
              {{ $t('home.homeView.pre1') }}
              <br class="hidden xs:block" />
              {{ $t('home.homeView.pre2') }}
              <br />
              {{ $t('home.homeView.pre3') }}
              <br />
              {{ $t('home.homeView.pre4') }}
            </div>

            <div class="mt-6 flex flex-wrap justify-center space-y-4 xs:justify-start xs:space-y-0 xs:space-x-4 items-center">
              <a :href="$bookDemoUrl()"
                 target="_blank"
                 class="w-full xs:w-auto max-w-mobile-btn xs:max-w-none uppercase px-8 py-4 font-medium text-green-500 bg-primary-900 rounded text-center min-w-[8rem]"
              >
                {{ $t('bookADemo') }}
              </a>

              <router-link :to="$freeTrialUrl()"
                           class="w-full xs:w-auto max-w-mobile-btn xs:max-w-none uppercase px-8 py-4 font-medium text-primary-900 bg-white rounded text-center min-w-[8rem]"
              >
                {{ $t('freeTrial') }}
              </router-link>
            </div>
          </div>
        </div>
        <Transition>
          <div v-if="savings && savingsShowingNow !== null"
               class="px-15 flex flex-grow lg:justify-end transition-all mt-8 xs:mt-0"
          >
            <div class="flex flex-col w-full xs:w-auto md:w-full lg:w-auto md:justify-between lg:justify-start md:flex-row lg:flex-col mt-16 lg:mt-[160px] xl:mt-[108px] xl:-mb-[80px]">
              <div class="relative">
                <img src="~@/assets/images/Main_leaf.svg"
                     class="hidden lg:block absolute w-32 -left-4 -bottom-1"
                />
              </div>
              <div class="relative self-center xs:self-start md:self-center lg:self-start md:mt-1 lg:mt-0 p-4 bg-white w-full sm:w-80 max-w-mobile-btn xs:max-w-none">
                <div class="absolute h-full w-[8px] bg-green-500 left-0 top-0"></div>
                <div class="absolute h-full w-[4px] bg-green-500 left-[12px] top-0"></div>
                <div class="absolute h-full w-[4px] bg-green-500 right-0 top-0"></div>

                <p class="text-center">{{ $t('home.savings.alreadySaved') }}</p>
                <Transition name="fade-in" mode="out-in">
                  <div :key="savingsShowingNow"
                       class="text-center"
                  >
                    <p>
                      <span class="text-2xl text-blue font-semibold">{{ savings[savingsShowingNow] }}</span>

                      <span v-if="savingsShowingNow === 'water_consumption'">
                      m<sup>3</sup>
                    </span>
                      <span v-else>
                      MWh
                    </span>
                    </p>
                    <p>
                      {{ $t(`home.savings.${savingsShowingNow}`) }}
                    </p>
                  </div>
                </Transition>
              </div>

              <div class="mt-12 xs:mt-16 md:mt-0 lg:mt-16 max-w-mobile-btn xs:max-w-[320px] self-center xs:self-start">
                <img src="~@/assets/images/Main_Bureau_veritas.png" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
  <HomeProduct></HomeProduct>
  <HomeDemo></HomeDemo>
</template>
<!--ChevronDown-->
<script>
import HomeProduct from "@/components/HomeProduct";
import HomeDemo from "@/views/HomeDemo";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FirstDesktop",
  data() {
    return {
      savingsShowingNow: null,
      intervalId: null, // Savings message change interval
    }
  },
  components: {
    HomeDemo,
    HomeProduct,
  },
  computed: {
    ...mapGetters('Savings', {
      savings: 'savings',
    })
  },
  methods: {
    ...mapActions('Savings', ['loadSavings']),
    changeSavingShowingNow() {
      if (typeof this.savings === 'undefined' || this.savings === null) {
        this.savingsShowingNow = null;
        return;
      }

      let keys = Object.keys(this.savings);

      if (this.savingsShowingNow === null) {
        this.savingsShowingNow = keys[0];
      } else {
        let currentIndex = keys.indexOf(this.savingsShowingNow);
        let newIndex;

        if (currentIndex >= keys.length - 1) {
          newIndex = 0;
        } else {
          newIndex = currentIndex + 1;
        }

        this.savingsShowingNow = keys[newIndex];
      }
    },
  },
  mounted() {
    this.loadSavings()
        .then(() => {
          this.changeSavingShowingNow();

          if (this.intervalId === null) {
            this.intervalId = setInterval(this.changeSavingShowingNow, 3000);
          }
        });
  },
  beforeUnmount() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/views/_homeView.scss";
</style>
